<template>
  <div v-if="!newSongList">
    <h2>正在加载中...</h2>
  </div>
  <div v-else class="newSong">
    <h2>最新音乐</h2>
    <div class="new-song">
      <div @dblclick="searchSingById(item)" class="playlist-table" v-for="item in newSongList" :key="item.id">
        <div class="left">
          <div class="img">
            <i @click="searchSingById(item)" class="el-icon-video-play"></i>
            <img style="width: 40px; height: 40px" v-lazy="item.album.blurPicUrl" alt="" />
          </div>
          <div class="song-name">
            <h3>
              {{ item.name }}
              <!-- <span v-for="item in item.al.alia" :key="item.id">({{ item }})</span> -->
            </h3>
            <p>
              <router-link v-for="(item, index) in item.artists" :key="index" :to="{ name: 'singerinfo', query: { id: item.id } }">{{ item.name }} </router-link>
            </p>
          </div>
        </div>
        <!-- <div class="song-album">{{ item.album.name }}</div> -->
        <p>
          <span>{{ item.duration | timeFormat }}</span>
        </p>
      </div>
    </div>
    <!-- <el-table :show-header="false" @row-dblclick="searchSingById" stripe ref="singleTable" :data="newSongList" highlight-current-row style="width: 100%">
      <el-table-column align="center" width="50">
        <template slot-scope="scope">
          <i @click="searchSingById(scope.row)" class="el-icon-video-play"></i>
        </template>
      </el-table-column>
      <el-table-column align="center" width="80">
        <template slot-scope="scope">
          <img class="blur-img" v-lazy="scope.row.album.blurPicUrl" alt="" />
        </template>
      </el-table-column>

      <el-table-column label="名称" width="450">
        <template v-slot="scope">
          <div class="song-name">
            {{ scope.row.name }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="歌手" width="220">
        <template v-slot="scope">
          <div class="singer-name">
            <router-link :to="{ name: 'singerinfo', query: { id: scope.row.artists[0].id } }">{{ scope.row.artists[0].name }}</router-link>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="专辑" width="150">
        <template v-slot="scope">
          <div class="song-time">
            {{ scope.row.album.name }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="时间" width="320">
        <template v-slot="scope">
          <div class="song-time">
            {{ scope.row.duration | timeFormat }}
          </div>
        </template>
      </el-table-column>
    </el-table> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      newSongList: '',
      sangInfo: '',
    }
  },
  methods: {
    // 获取最新音乐数据
    async getNewSong() {
      // if (this.newSongList) return
      const { data: res } = await this.$http.get('/top/song?type=0')
      // // console.log(res)
      if (res.code !== 200) return this.$message.error('获取最新歌曲列表失败，请刷新重试！')
      this.newSongList = res.data
    },
    // 获取传进来的id歌曲信息
    async searchSingById(val) {
      // // // console.log(val.id)
      // // // console.log(window.sessionStorage.getItem('cookie'))
      if (window.sessionStorage.getItem('cookie') == null) {
        this.$message.info('没有查询到登录信息！请登录体验完整功能！')
        const { data: res } = await this.$http.post(`/song/url?id=${val.id}`)
        // 用post请求 在请求体中加上cookie值
        // const { data: res } = await this.$http.post(`/song/url?id=${val.id}`, { cookie: window.sessionStorage.getItem('cookie') })
        // // // console.log('我没有登录听最新歌曲', res)
        if (res.code !== 200) return this.$message.info('获取列表失败！请查看是否登录或者刷新重试！')
        this.sangInfo = res.data
        // // // console.log(this.sangInfo)
        // // 获取到歌曲信息 判断是否为会员专享和没有歌曲信息
        if (this.sangInfo[0].code != 200) return this.$message.error('该歌曲必须登录或者是会员专享！')
        // // 通过全局事件总线传递数据
        this.$bus.$emit('sendSangUrl', this.sangInfo[0].url, this.newSongList, val.id)
        // this.audioUrl =
      } else {
        const { data: res } = await this.$http.post(`/song/url?id=${val.id}`, { cookie: window.sessionStorage.getItem('cookie') })
        // 用post请求 在请求体中加上cookie值
        // const { data: res } = await this.$http.post(`/song/url?id=${val.id}`, { cookie: window.sessionStorage.getItem('cookie') })
        // // // console.log(res)
        // // // console.log('我登录了听最新的歌曲', res)
        if (res.code !== 200) return this.$message.info('获取列表失败！请查看是否登录或者刷新重试！')
        this.sangInfo = res.data
        // console.log(this.newSongList)
        // // 获取到歌曲信息 判断是否为会员专享和没有歌曲信息
        if (this.sangInfo[0].code != 200) return this.$message.error('该歌曲必须登录或者是会员专享！')
        // // 通过全局事件总线传递数据
        this.$bus.$emit('sendSangUrl', this.sangInfo[0].url, this.newSongList, val.id, 'newSong')
        // this.audioUrl =
      }
    },
  },
  mounted() {
    this.getNewSong()
  },
}
</script>

<style lang="less" scoped>
.song-time {
  font-size: 13px;
  color: #b0adad;
}
.song-name {
  color: black;
  font-size: 15px;
  font-weight: 600;
}
.singer-name {
  a {
    color: rgb(59, 54, 119);
  }
  a:hover {
    color: black;
    border-bottom: 1px solid rgb(48, 63, 201);
  }
}
h2 {
  margin: 20px 0 30px 0;
}
.blur-img {
  width: 60px;
  height: 60px;
  vertical-align: middle;
  border-radius: 5px;
}
i {
  font-size: 20px;
  color: rgb(158, 155, 155);
  // margin-top: 5px;
}
.el-table__row:hover i {
  color: black;
}
i:hover {
  cursor: pointer;
}
.new-song {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  // display: grid;
  // gap: 4px;
  // grid-template-columns: repeat(3, 1fr);
}
.playlist-table:hover {
  background-color: rgba(151, 151, 151, 0.363);
}
.playlist-table:hover > .left > .img > i {
  display: block;
  color: rgb(214, 200, 200);
}
.playlist-table {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 15px;
  // height: 60px;
  width: 31%;
  // border: 1px solid #ccc;
  padding: 10px 10px;
  margin: 5px 0;
  transition: all 0.6s;
  .left {
    .img {
      position: relative;
      height: 40px;
      width: 40px;
      i {
        display: none;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        // display: none;
        font-size: 20px;
        color: rgb(201, 126, 167);
        // margin-top: 5px;
        margin-right: 5px;
      }

      i:hover {
        cursor: pointer;
      }
    }

    display: flex;
    align-items: center;
    .song-name {
      margin-left: 10px;
      // height: 45px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      h3 {
        width: 270px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        // font-size: 17px;
        font-weight: 600;
        span {
          color: rgb(143, 138, 138);
        }
      }
      a:hover {
        color: rgba(0, 0, 0);
        text-decoration: underline;
      }
      p {
        width: 270px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        a {
          font-size: 12px;
          color: rgb(88, 88, 88);
          font-weight: 400;
        }
      }
    }
  }
  .song-album {
    font-size: 15px;
    color: rgb(30, 30, 30);
    flex-grow: 1;
    margin: 0 auto;
  }
  p {
    color: rgb(88, 88, 88);
    font-size: 12px;
    // flex-grow: 1;
    span {
      font-size: 13px;
    }
  }
}
</style>
