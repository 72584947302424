<template>
  <div v-if="!personalizedList">
    <h2>正在加载中....</h2>
  </div>
  <article v-else class="markdown-body">
    <div class="start">
      <!-- <h1>展位</h1> -->
      <el-carousel :interval="4000" type="card" height="200px">
        <el-carousel-item v-for="item in newestList" :key="item.id">
          <!-- <h3 class="medium"> -->
          <img v-lazy="item.imageUrl" alt="" />
          <!-- </h3> -->
        </el-carousel-item>
      </el-carousel>
    </div>
    <!-- 推荐歌单 -->
    <div class="recommend">
      <h2>推荐歌单 <i class="el-icon-arrow-right"></i></h2>
      <div class="big-box">
        <div class="small-box" v-for="item in personalizedList" :key="item.id">
          <router-link :to="{ path: '/doc/recommend', query: { id: item.id } }">
            <img v-lazy="item.picUrl" alt="" />
            <h4>{{ item.name }}</h4>
          </router-link>
          <div class="screen"><i class="el-icon-video-play"></i> {{ item.playCount | numberTextFormat }}</div>
        </div>
      </div>
    </div>

    <!-- 右侧弹窗 -->
  </article>
</template>

<script>
export default {
  data() {
    return {
      // 最新专辑列表
      newestList: '',
      // 推荐歌单列表
      personalizedList: '',
    }
  },
  methods: {
    // 获取最新专辑列表
    async getNewest() {
      // products
      const { data: res } = await this.$http.get('banner?type=0')
      // // console.log(res)
      if (res.code !== 200) return this.$message('获取数据失败！请登录重试。')
      this.newestList = res.banners
    },
    // 获取推荐歌单信息
    async getPersonalized() {
      // products
      const { data: res } = await this.$http.get('personalized?limit=10')
      // // console.log(res)
      // this.newestList = res.banners

      if (res.code !== 200) return this.$message('获取数据失败！请登录重试。')
      this.personalizedList = res.result
    },

    async login() {
      const { data: res } = await this.$http.post(`https://music-api-three-liard.vercel.app/login/cellphone?`, { phone: 18435933438, password: 'Yzh010603' })
      // console.log(res)
      if (res.code !== 200) return this.$message.error('密码错误！')
      window.sessionStorage.setItem('cookie', res.cookie)
      this.$message.success('登陆成功！')
      window.localStorage.setItem('activeName', '/doc/intro')
    },
  },
  mounted() {
    this.getNewest()
    this.getPersonalized()
    this.login()
  },
}
</script>

<style lang="less" scoped>
.markdown-body {
  span {
    color: #ff0000;
  }
  .start {
    /deep/.el-carousel {
      // width: 80% !important;
      .el-carousel__item {
        // background-color: wheat;
        // width: 30%;
        overflow: hidden;
        // text-align: center;
        // border-radius: 20px;
        border-radius: 20px;
        overflow: hidden;
        // h3 {
        img {
          width: 100%;
          height: 100%;
          border-radius: 20px;
          overflow: hidden;
        }
        // }
      }
    }

    .first-welcome {
      color: rgb(78, 78, 78);
      border-bottom: 1px solid #ee9ca7;
      margin-top: 40px;
      padding: 2px 0;
      margin-bottom: 30px;
    }
    h1 {
      font-size: 2em;
    }
  }
  .intro {
    h2 {
      margin-bottom: 30px;
    }
    margin-bottom: 150px;
  }
  .update {
    background-color: #f7eeeea8;
    border-left: 5px solid #ee9ca7;
    padding: 5px 20px;

    h4 {
      color: rgb(104, 104, 104);
    }
    p {
      margin-top: 15px;
      margin-left: 38px;
      font-size: 15px;
      color: rgb(99, 99, 99);
      code {
        color: #ee465d;
        font-weight: 600;
      }
    }
  }
  .line {
    margin-top: 15px;
    margin-bottom: 50px;
  }
  .recommend {
    width: 100%;
    // height: 666px;
    .big-box {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      // margin-bottom: 50px;
      border-radius: 20px;
      margin-top: 20px;
      .small-box:hover img {
        box-shadow: 0 5px 30px rgb(134, 132, 132);
      }
      .small-box {
        position: relative;
        // width: 220px;
        width: 18%;
        // min-width: 18%;
        min-height: 273px;
        margin: 5px 0;
        // height: 50%;

        // overflow: hidden;
        a {
          p {
            font-size: 14px;
            color: black;
          }
        }
        .screen {
          position: absolute;
          top: 0;
          right: 5px;
          color: white;
          font-size: 13px;
        }
        img {
          width: 100%;
          min-height: 180px;
          border-radius: 10px;
          transition: all 0.3s;
        }
      }
    }
  }
}
/deep/.el-carousel__container {
  height: 240px !important;
}
</style>
