<template>
  <div v-if="!singerList">
    <h2>正在加载中...</h2>
  </div>
  <div v-else class="singer">
    <!-- <h2>热门歌手</h2> -->
    <div class="sing-type">
      <div class="lang-type">
        <p>语种：</p>
        <ul>
          <li @click="toggleActive(item)" v-for="item in language" :key="item.id">
            <router-link to="" :class="item.class">{{ item.name }}</router-link>
          </li>
        </ul>
      </div>
      <div class="classify-type">
        <p>分类：</p>
        <ul>
          <li @click="toggleTypeActive(item)" v-for="item in type" :key="item.id">
            <router-link to="" :class="item.class">{{ item.name }}</router-link>
          </li>
        </ul>
      </div>
      <div class="sing-list">
        <div class="singer-box" v-for="item in singerList" :key="item.id">
          <router-link :to="{ name: 'singerinfo', query: { id: item.id } }">
            <img v-lazy="item.img1v1Url" alt="" />
          </router-link>

          <p>
            <router-link :to="{ name: 'singerinfo', query: { id: item.id } }"> {{ item.name }}</router-link>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      singerList: [],
      activeName: '',
      areaId: -1,
      language: [
        { id: 1, class: 'active-type', name: '全部', area: 'all', n: -1 },
        { id: 2, class: '', name: '华语', area: 'china', n: 7 },
        { id: 3, class: '', name: '欧美', area: 'west', n: 96 },
        { id: 4, class: '', name: '日本', area: 'jap', n: 8 },
        { id: 5, class: '', name: '韩国', area: 'korea', n: 16 },
        { id: 6, class: '', name: '其他', area: 'other', n: 0 },
      ],
      typeId: -1,
      type: [
        { id: 1, class: 'active-type', name: '全部', value: 'all', n: -1 },
        { id: 2, class: '', name: '男歌手', value: 'male', n: 1 },
        { id: 3, class: '', name: '女歌手', value: 'female', n: 2 },
      ],
    }
  },
  watch: {
    areaId() {
      this.getSingerList()
    },
    typeId() {
      this.getSingerList()
    },
  },
  methods: {
    toggleActive(val) {
      this.areaId = val.n
      // this.language.forEach((item) => (item.class = ''))
      this.language.forEach((item) => {
        item.class = ''
        if (item.id == val.id) {
          item.class = 'active-type'
        }
      })
    },
    toggleTypeActive(val) {
      this.typeId = val.n
      // this.language.forEach((item) => (item.class = ''))
      this.type.forEach((item) => {
        item.class = ''
        if (item.id == val.id) {
          item.class = 'active-type'
        }
      })
    },
    // 获取热门歌手列表
    async getSingerList() {
      const { data: res } = await this.$http.get(`/artist/list?type=${this.typeId}&area=${this.areaId}`)
      // // // console.log(res)
      if (res.code !== 200) return this.$message.error('获取歌手列表失败，请刷新重试！')
      this.singerList = res.artists
    },
  },
  mounted() {
    // this.getUserPlaylist()
    this.getSingerList()
  },
}
</script>

<style lang="less" scoped>
h2 {
  margin-top: 20px;
}
/deep/.active-type {
  color: rgb(243, 113, 178) !important;
  background-color: rgba(255, 230, 237, 0.377);
}
.sing-type {
  font-size: 13px;
  color: rgb(143, 143, 143);
  display: flex;
  flex-direction: column;
  .lang-type,
  .classify-type {
    display: flex;
    align-items: center;
    margin: 10px 0;
    ul li:hover a {
      color: rgb(235, 63, 149) !important;
      background-color: rgba(253, 171, 195, 0.377);
    }
    ul {
      display: flex;
      flex-grow: 1;
      li {
        // background-color: rgba(255, 173, 195, 0.377);
        margin: 0 5px;
        a {
          border-radius: 5px;
          display: block;
          padding: 1px 6px;
          color: black;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .sing-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 20px;
    .singer-box:hover img {
      box-shadow: 0 10px 20px rgb(122, 120, 120);
    }
    .singer-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 220px;
      height: 260px;
      border-radius: 10px;
      // border: 1px solid #ccc;

      img {
        width: 200px;
        // width: 100%;
        height: 200px;
        border-radius: 50%;
        box-shadow: 0 0 0px #ccc;
        transition: all 0.5s;
        // height: 210px;.
      }

      p {
        margin-top: 7px;
        // margin-bottom: 20px;
        font-size: 15px;
        font-weight: 600;
        color: black;
        a:hover {
          border-bottom: 1px solid rgb(110, 110, 110);
        }
      }
    }
  }
}
</style>
