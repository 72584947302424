<template>
  <div class="login-container">
    <!-- <h1>login组件</h1> -->

    <div class="login-aside">
      <!-- <el-button type="primary"> 返回主页</el-button> -->
      <img src="../assets/music-logo.png" alt="" />
      <h3>登陆网易云账号</h3>
    </div>
    <div class="login_box">
      <div class="qr-box">
        <!-- <vue-qr :logoSrc="config.imagePath" :text="config.qrImgUrl" :size="220"></vue-qr> -->
        <!-- 密码输入框 -->
        <p>打开网易云音乐APP扫码登录</p>
      </div>
      <!-- 登录表单 -->
      <div class="form-box">
        <div class="input-s">
          <!-- 手机号输入框 -->
          <el-input placeholder="手机号" v-model="cellphone" prefix-icon="iconfont icon-3702mima"> <i slot="prefix" class="el-input__icon el-icon-phone"></i></el-input>
          <!-- 密码输入框 -->
          <el-input placeholder="密码" v-model="rulesNum" type="password" prefix-icon="iconfont icon-3702mima"><i slot="prefix" class="el-input__icon el-icon-lock"></i></el-input>
        </div>
        <div class="btn-s">
          <el-button type="primary" @click="login">登录</el-button>
          <el-button plain @click="register">注册</el-button>
        </div>
      </div>
      <!-- 手机号输入框 -->
    </div>
  </div>
  <!-- <div class="login">
    <div class="login-top">
      <el-button>回到首页</el-button>
      <img src="../assets/music-logo.png" alt="" />
    </div>
    <h2>登录网易云账号</h2>
  </div> -->
</template>

<script>
import axios from 'axios'
// import VueQr from 'vue-qr'
export default {
  components: {
    // VueQr,
  },
  name: 'Login',
  data() {
    return {
      // config: {
      //   qrImgUrl: 'https://www.baidu.com', //显示的值、跳转的地址
      //   // imagePath: require('../assets/music-logo.png'), //中间logo的地址
      //   BYTE_POS: 'green',
      // },
      qrImgUrl: '',
      activeName: 'cellphone',
      tabPosition: 'left',
      // 登陆验证手机号
      cellphone: '18435933438',
      rulesNum: 'Yzh010603',
      // 这是登录表单的数据绑定对象
      // 这是表单验证对象
      // loginrules: {
      //   cellphone: [
      //     { required: true, message: '请输入用户名', trigger: 'blur' },
      //     { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' },
      //   ],
      //   password: [
      //     { required: true, message: '请输入密码', trigger: 'blur' },
      //     { min: 6, max: 15, message: '长度在 6 到 15 个字符', trigger: 'blur' },
      //   ],
      // },
      unikey: '',
    }
  },
  watch: {
    activeName(val) {
      if (val == 'twoimg') {
        this.getQrKey()
      }
    },
    unikey() {
      // setInterval(() => {
      //   this.checkQrStatus()
      // }, 4000)
    },
  },
  methods: {
    async login() {
      const { data: res } = await axios.post(`https://music-api-three-liard.vercel.app/login/cellphone?`, { phone: this.cellphone, password: this.rulesNum })
      // console.log(res)
      if (res.code !== 200) return this.$message.error('密码错误！')
      window.sessionStorage.setItem('cookie', res.cookie)
      // this.$bus.$emit('sendUserList', res.profile)
      sessionStorage.setItem('UserId', res.profile.userId)
      this.$router.push('/doc')
      this.$message.success('登陆成功！')
      window.localStorage.setItem('activeName', '/doc/intro')
    },
    // 获取二维码key值
    // async getQrKey() {
    //   const { data: res } = await this.$http.get(`/login/qr/key`)
    //   // console.log(res.data.unikey)
    //   // console.log(res)
    //   this.unikey = res.data.unikey
    //   if (res.code !== 200) return this.$message.error('获取二维码key失败，请重新刷新再试！')
    //   const { data } = await this.$http.get(`/login/qr/create?key=${this.unikey}`)
    //   console.log(data)
    //   this.config.qrImgUrl = data.data.qrurl
    //   // setInterval(() => {
    //   //   this.checkQrStatus()
    //   // }, 1500)
    // },
    // async checkQrStatus() {
    //   const { data: res } = await this.$http.get(`/login/qr/check?key=${this.unikey}&timestamp=${new Date()}`)
    //   console.log(res)
    // },
    // 点击发送手机验证码
    // async sendNum() {
    //   const data = await axios.post('https://music-api-three-liard.vercel.app/captcha/sent?phone=' + this.cellphone)
    //   console.log(data)
    //   // console.log(this.cellphone)
    // },
    // async update() {
    //   const data = await axios.post('https://music-api-three-liard.vercel.app/login/status')
    //   console.log(data)
    //   console.log(this.cellphone)
    // },
    register() {},
  },
  mounted() {
    // this.getQrKey()
  },
}
</script>

<style lang="less" scoped>
.el-tabs__header {
  padding: 0;
  position: relative;
  margin: -5px 0px 25px;
}
.login-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  align-items: center;
  // width: 100%;
  background-color: #ffbcd838;

  .login-aside {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 80px;
    // width: 180px;
    img {
      width: 100px;
      height: 100px;
    }
    h3 {
      margin-top: 10px;
      text-align: center;
    }
  }
  .login_box {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 50vw;
    height: 300px;
    // margin-bottom: 1px solid #ccc;
    // background-color: #aaeb8d;

    .qr-box {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-right: 1px solid rgb(250, 106, 144);
      img {
        border-radius: 5px;
        // border: 1px solid rgb(231, 95, 174);
        // background-color: skyblue;
        width: 150px;
        height: 150px;
      }
      p {
        margin-top: 10px;
        font-size: 17px;
        font-weight: 600;
        color: rgb(255, 133, 166);
      }
    }
    .form-box {
      height: 300px;
      justify-content: center;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      // margin-left: 70px;
      flex: 1;
      // box-sizing: border-box;
      // margin-left: 50px;
      .input-s {
        width: 300px;
        /deep/ .el-input__inner:focus {
          border-color: rgb(255, 133, 166) !important;
          background-color: rgba(170, 240, 240, 0.534) !important;
          outline: 0;
        }
        .el-input {
          color: rgb(241, 69, 184);
          // padding: 10px 0 10px 25px;
          margin-bottom: 20px;
          height: 40px;
          border-radius: 3px;
          outline: rgb(241, 69, 184);
          border: none;
        }
      }
      .btn-s {
        width: 300px;
        // margin-left: 40px;
        display: flex;
        justify-content: space-between;
        /deep/.el-button {
          width: 110px !important;
        }
        .el-button--primary {
          background-color: rgb(255, 133, 166);
          border-color: rgb(255, 133, 166);
        }

        .el-button.is-plain:active {
          color: rgb(255, 133, 166);
          border-color: rgb(255, 133, 166);
        }
        .el-button.is-plain:focus,
        .el-button.is-plain:hover {
          color: rgb(255, 133, 166);
          border-color: rgb(255, 133, 166);
        }
      }
    }
  }
}

// .login {
//   height: 100vh;
//   width: 100%;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
//   .login-top {
//     width: 500px;
//     display: flex;
//     flex-direction: column;
//   }
// }
/deep/.el-tabs__nav-wrap,
.is-left {
  height: 80px !important;
}
</style>
