<template>
  <div v-if="!newMvList">
    <h2>正在加载中...</h2>
  </div>
  <div v-else class="new-mv">
    <h2>最新MV</h2>
    <div class="video">
      <div class="video-small" v-for="item in newMvList" :key="item.id">
        <div class="video-img">
          <img @click="showMvDialog(item.id)" v-lazy="item.cover" alt="" />
          <div class="screen"><i class="el-icon-video-play"></i> {{ item.playCount | numberTextFormat }}</div>
          <i class="el-icon-video-play"></i>
        </div>
        <div class="video-info">
          <h3 class="video-keywords">{{ item.name }}</h3>
          <!-- <p class="singer">{{ item.artistName }}</p> -->
          <p class="singer">
            <router-link :to="{ name: 'singerinfo', query: { id: item.artistId } }">{{ item.artistName }}</router-link>
          </p>
        </div>
      </div>
    </div>

    <el-dialog :visible.sync="mvDialogVisible" :close-on-click-modal="false" @close="closeEvent" width="50%">
      <video v-if="mvDialogVisible" autoplay :src="mvInfo.url" controls></video>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // mv数据存放
      newMvList: [],
      // mv盒子显示布尔值
      mvDialogVisible: false,
      // 歌曲mv信息
      mvInfo: [],
    }
  },
  methods: {
    // 获取最新mv列表数据
    async getNewMv() {
      const { data: res } = await this.$http.get('/mv/first?limit=32')
      if (res.code !== 200) return this.$message.error('获取最新mv失败！请刷新或者登录重试！')
      // // // console.log(res)
      this.newMvList = res.data
    },
    // 点击mv盒子播放
    async showMvDialog(id) {
      this.$bus.$emit('pauseSang')
      this.mvDialogVisible = true
      const { data: res } = await this.$http.get('/mv/url?id=' + id)
      // // // console.log(res)
      this.mvInfo = res.data
    },
    // 关闭盒子事件
    closeEvent() {
      this.$bus.$emit('actionSang')
      this.mvDialogVisible = false
    },
  },
  mounted() {
    this.getNewMv()
  },
}
</script>

<style lang="less" scoped>
/deep/.el-dialog__header,
/deep/.el-dialog__body {
  background-color: rgb(0, 0, 0) !important;
}
h2 {
  margin: 20px 0 30px 0;
}
video {
  width: 100%;
  height: 100%;
}
.video {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .video-small {
    position: relative;
    display: flex;
    // width: 275px;
    width: 22%;
    height: 250px;
    border-radius: 5px;
    // overflow: hidden;
    flex-direction: column;

    .video-info {
      // width: 0;
      // flex: 1;
      p {
        width: 100%;
        // flex: 1;
        font-weight: normal;
        // width: 270px;
      }
      .singer {
        color: rgba(80, 125, 175);
        font-size: 12px;
        a {
          color: rgb(59, 54, 119);
        }
        a:hover {
          color: black;
        }
      }
      .video-keywords {
        font-size: 14px;
        // display: flex;
        width: 100%;
        // max-width: 280px;
        align-items: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 2px 0px 3px 0;
        span {
          font-size: 12px;
          display: inline-block;
          border: 1px solid #ff85a6;
          color: #ff85a6;
          font-weight: 600;
          border-radius: 3px;
          margin-right: 3px;
        }
      }
    }
    .video-img:hover > i {
      font-size: 40px;
      color: white;
    }
    .video-img > i {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: rgba(204, 204, 204, 0);
      transition: all 0.3s;
    }
    .video-img:hover img {
      box-shadow: 0 10px 20px #ccc;
    }
    .video-img {
      cursor: pointer;
      position: relative;
      // height: 180px;

      .screen {
        position: absolute;
        top: 0;
        right: 5px;
        color: white;
        font-size: 13px;
      }
      .time-screen {
        position: absolute;
        bottom: 6px;
        right: 5px;
        color: white;
        font-size: 13px;
      }
      img {
        width: 100%;
        height: 180px;
        transition: all 0.3s;
      }
    }
  }
}
</style>
