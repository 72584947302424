import Vue from 'vue'
import App from './App.vue'
// import './components/plugins/element.js'
import router from './router/index.js'
import axios from 'axios'
import VueLazyLoad from 'vue-lazyload'
import { showLoading, hideLoading } from './loading';

// 导入 NProgress包对应的js和css
import NProgress from 'nprogress'

import './assets/iconfont.css'
import { Loading } from 'element-ui'
// import '../vue-happy-scroll/docs/happy-scroll.css'
import '../node_modules/vue-happy-scroll/docs/happy-scroll.css'

// 配置axios根路径
axios.defaults.baseURL = 'https://music-api-three-liard.vercel.app/'

// ...



 
/* 请求拦截器（请求之前的操作） */
axios.interceptors.request.use((req) => {
  // if (sessionStorage.getItem('cookie') == null) {
  //   this.$message.info('请先登录体验完整功能！')
  // }
  // showLoading();
  NProgress.start()
  return req;
});
 
/* 请求之后的操作 */
axios.interceptors.response.use((res) => {
  // hideLoading();
  NProgress.done()
 
  return res;
 
});

Vue.prototype.$http = axios
Vue.config.productionTip = false

// 全局时间过滤器
Vue.filter('dataFormat',  (originVal)=> {
  const dt = new Date(originVal)

  const y = dt.getFullYear()
  const m = (dt.getMonth() + 1 + '').padStart(2, '0')
  const d = (dt.getDate() + '').padStart(2, '0')

  const hh = (dt.getHours() + '').padStart(2, '0')
  const mm = (dt.getMinutes() + '').padStart(2, '0')
  const ss = (dt.getSeconds() + '').padStart(2, '0')
  // yyyy-mm-dd hh:mm:ss
  //  ${hh}:${mm}:${ss}
  return `${y}年${m}月${d}日`
})

Vue.filter('timeFormat',  (originVal)=> {
  const dt = new Date(originVal)

  // const y = dt.getFullYear()
  // const m = (dt.getMonth() + 1 + '').padStart(2, '0')
  // const d = (dt.getDate() + '').padStart(2, '0')

  // const hh = (dt.getHours() + '').padStart(2, '0')
  const mm = (dt.getMinutes() + '').padStart(2, '0')
  const ss = (dt.getSeconds() + '').padStart(2, '0')
  // yyyy-mm-dd hh:mm:ss
  // ${y}-${m}-${d} ${hh}:
  //  ${hh}:${mm}:${ss}
  return `${mm}:${ss}`
})


// 全局数量过滤器
// 数字转"万" 过滤器
Vue.filter('numberTextFormat', (value) => {
  if(value !== undefined && value !== null) {
    if (Number(value) < 10000) {
      return value
      // let str = value.toString();
      // let reg = str.indexOf('') > -1 ? /(\d)(?=(\d{3})+\.)/g : /(\d)(?=(?:\d{3})+$)/g;
      // return str.replace(reg, '$1,');
    } else {
      let str = Number((Number(value) / 10000).toFixed(0)).toString();
      let reg = str.indexOf('') > -1 ? /(\d)(?=(\d{3})+\.)/g : /(\d)(?=(?:\d{3})+$)/g;
      return str.replace(reg, '$1,') +'万';
    }
  }else{
    return '';
  }
});
// 括号 过滤器
Vue.filter('bracketColor', (value) => {
  if(value !== undefined && value !== null) {
    if (Number(value) < 10000) {
      return value
      // let str = value.toString();
      // let reg = str.indexOf('') > -1 ? /(\d)(?=(\d{3})+\.)/g : /(\d)(?=(?:\d{3})+$)/g;
      // return str.replace(reg, '$1,');
    } else {
      let str = Number((Number(value) / 10000).toFixed(0)).toString();
      let reg = str.indexOf('') > -1 ? /(\d)(?=(\d{3})+\.)/g : /(\d)(?=(?:\d{3})+$)/g;
      return str.replace(reg, '$1,') +'万';
    }
  }else{
    return '';
  }
});

Vue.use(VueLazyLoad, {
  loading: require('./components/assets/logo1.png'),
  error: require('./components/assets/logo1.png')
})

new Vue({
  render: h => h(App),
  beforeCreate () {
    Vue.prototype.$bus = this
  },
  router,
}).$mount('#app')
