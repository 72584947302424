<template>
  <footer v-if="songInfo" class="footer">
    <div class="bar">
      <!-- 进度条遮罩层 -->
      <div @click="updatePlayTime" @mousedown="moveDown" @mousemove="moveStart" @mouseup="moveOut" class="wrap"></div>
      <!-- 进度条头 -->
      <div ref="circleRefs" class="circle"></div>
      <!-- 进度条 -->
      <el-progress v-if="percentageTime" @click="updatePlayTime" :show-text="false" :percentage="percentageTime"></el-progress>
    </div>
    <div v-if="songInfo" class="actionTime">{{ currentTime }}</div>
    <div v-if="songInfo" class="duration">{{ songInfo.dt | timeFormat }}</div>
    <div class="audio">
      <div class="left-intro">
        <img style="width: 50px; height: 50px; border-radius: 5px" v-lazy="songInfo ? songInfo.al.picUrl : staticUrl" alt="" />
        <div class="content">
          <h3 v-if="songInfo">{{ songInfo.name }}</h3>
          <p>
            <router-link :to="{ name: 'singerinfo', query: { id: item.id } }" v-for="(item, index) in songInfo.ar" :key="index"> {{ item.name }}</router-link>
          </p>
        </div>
      </div>
      <!-- 前进后退暂停按钮 -->
      <div class="btn-arr">
        <i @click="playPre" class="iconfont icon-shangyishou"></i>
        <i ref="toggleRefs" @click="togglePlay" class="iconfont icon-zanting"></i>
        <i @click="playNext" class="iconfont icon-xiayishou"></i>
      </div>
      <!-- 播放方式 -->
      <div class="play-way">
        <div class="way-btn">
          <div class="loop"><span title="列表循环" @click="playListCycle" class="iconfont icon-xunhuan list-cycle" style="color: pink"> </span></div>
          <div class="loop list-single">
            <span title="单曲循环" @click="playSingle" class="iconfont icon-danquxunhuan single"></span>
          </div>
          <div class="loop list-random"><span title="随机播放" @click="playRandom" class="iconfont icon-suijibofang random"></span></div>
        </div>
        <div class="laba"><span title="静音" ref="toggleMuteRefs" @click="toggleMute" class="iconfont icon-laba-fill"></span></div>
        <div class="column">
          <el-progress :color="customColor" :show-text="false" :percentage="volumeNum"></el-progress>
          <div @click="updatedVolume" class="wrap column-wrap"></div>
        </div>
      </div>
    </div>
    <audio v-if="songInfo" ref="audio" :loop="playLoop" :src="audioUrl" controls autoplay @timeupdate="updateTime" @ended="playModel"></audio>
  </footer>
</template>

<script>
export default {
  mounted() {
    // // // // console.log(this.$refs.audio.duration)
    this.$bus.$on('sendSangUrl', this.getSangUrl)
    this.$bus.$on('pauseSang', this.pauseSang)
    this.$bus.$on('actionSang', this.actionSang)
  },
  methods: {
    // 切换上一首
    playPre() {
      this.index--
      if (this.index == -1) {
        this.index = this.playList.length - 1
        this.playList.forEach((item, index) => {
          if (this.index == index) {
            this.songInfo = item
            this.searchSingById(item)
          }
        })
      } else {
        this.playList.forEach((item, index) => {
          if (this.index == index) {
            this.songInfo = item
            this.searchSingById(item)
          }
        })
      }
    },
    // 切换下一首
    playNext(way) {
      // 随机播放
      if (way == 'random') {
        this.index = Math.floor(Math.random() * (this.playList.length + 1))
        this.playList.forEach((item, index) => {
          if (this.index == index) {
            this.songInfo = item
            this.searchSingById(item)
          }
        })
      } else if (way == 'single') {
        this.playLoop = true
        this.$refs.audio.play()
      } else {
        // 顺序播放
        this.index++
        if (this.index == this.playList.length) {
          this.index = 0
        }

        this.playList.forEach((item, index) => {
          if (this.index == index) {
            this.songInfo = item
            this.searchSingById(item)
          }
        })
      }

      // this.songInfo.forEach((item) => {})
    },

    // 暂停歌曲播放
    pauseSang() {
      this.$refs.toggleRefs.classList.remove('icon-zanting')
      this.$refs.toggleRefs.classList.add('icon-bofang')
      this.$refs.audio.pause()
    },

    // 继续歌曲播放
    actionSang(val) {
      this.$refs.toggleRefs.classList.remove('icon-bofang')
      this.$refs.toggleRefs.classList.add('icon-zanting')
      this.$refs.audio.play()
    },

    // 切换播放暂停
    togglePlay(e) {
      if (e.target.classList.contains('icon-zanting')) {
        this.$refs.toggleRefs.classList.remove('icon-zanting')
        this.$refs.toggleRefs.classList.add('icon-bofang')
        this.$refs.audio.pause()
      } else {
        this.$refs.toggleRefs.classList.remove('icon-bofang')
        this.$refs.toggleRefs.classList.add('icon-zanting')
        this.$refs.audio.play()
      }
    },

    // 拿到传来的歌曲列表信息

    getSangUrl(url, res, id, words) {
      // // // console.log(url, res, id, words)
      if (words) {
        // // // console.log(url, res)
        res = JSON.parse(JSON.stringify(res).replace(/album/g, 'al')) //data为数组，title为修改前，name为修改后
        res = JSON.parse(JSON.stringify(res).replace(/artists/g, 'ar'))
        res = JSON.parse(JSON.stringify(res).replace(/duration/g, 'dt'))
      }
      // this.$refs.audio.play()
      this.audioUrl = url
      // 遍历拿到的歌曲列表 找到双击的歌曲id 找到歌曲对应信息
      res.forEach((item, index) => {
        if (item.id == id) {
          this.index = index
          this.songInfo = item
        }
      })
      this.playList = res
      // // // console.log(this.index)
    },
    getDuration(val) {},
    // 获取传进来的id歌曲信息
    async searchSingById(val) {
      // // // // console.log(val.id)
      // 用post请求 在请求体中加上cookie值
      if (window.sessionStorage.getItem('cookie') == null) {
        this.$message.info('没有查询到登录信息！请登录体验完整功能！')
        const { data: res } = await this.$http.post(`/song/url?id=${val.id}`)
        // // // console.log('我没有登录', res)
        if (res.code !== 200) return this.$message.info('获取列表失败！请查看是否登录！')

        // // // console.log(this.sangInfo)
        // 获取到歌曲信息 判断是否为会员专享和没有歌曲信息
        if (this.sangInfo[0].code != 200) return this.$message.error('该歌曲必须登录或者是会员专享！')
        this.sangInfo = res.data
        // 通过全局事件总线传递数据
        // this.$bus.$emit('sendSangUrl', this.sangInfo[0].url)
      } else {
        // // console.log(val)
        const { data: res } = await this.$http.post(`/song/url?id=${val.id}`, { cookie: window.sessionStorage.getItem('cookie') })
        // const { data: res } = await this.$http.post(`/song/url?id=${val.id}`, { withCredentials: true })
        // console.log('我登录了', res)
        if (res.code !== 200) return this.$message.info('获取列表失败！请查看是否登录！')
        this.sangInfo = res.data
        // // // console.log(this.sangInfo)
        // 获取到歌曲信息 判断是否为会员专享和没有歌曲信息
        if (this.sangInfo[0].code != 200) return this.$message.error('该歌曲必须登录或者是会员专享！')
        this.audioUrl = this.sangInfo[0].url
        // this.audioUrl =
      }
    },

    // 歌曲播放结束操作
    playModel() {
      if (this.playMode == 'random') {
        this.playNext('random')
      } else if (this.playMode == 'single') {
        this.playNext('single')
      } else {
        this.playNext()
      }
    },

    // 列表循环
    playListCycle() {
      this.playLoop = false
      this.playMode = 'listCycle'
    },
    // 单曲循环
    playSingle() {
      this.playLoop = true
      this.playMode = 'single'
    },
    // 随机播放
    playRandom() {
      this.playLoop = false
      this.playMode = 'random'
    },

    s_to_hs(s) {
      //计算分钟
      //算法：将秒数除以60，然后下舍入，既得到分钟数
      var h
      h = Math.floor(s / 60)
      //计算秒
      //算法：取得秒%60的余数，既得到秒数
      s = s % 60
      //将变量转换为字符串
      h += ''
      s += ''
      //如果只有一位数，前面增加一个0
      h = h.length == 1 ? '0' + h : h
      s = s.length == 1 ? '0' + s : s
      return h + ':' + s
    },

    // 时间改变时触发事件
    updateTime() {
      if (this.dragFlag) return
      const circle = document.querySelector('.circle')
      const wrap = document.querySelector('.wrap')
      // 获取当前播放时间点

      const currentTime = parseInt(this.$refs.audio.currentTime)

      this.currentTime = this.s_to_hs(currentTime)
      // console.log(this.currentTime)
      // 获取当前音乐总时长
      const durationTime = parseInt(this.$refs.audio.duration)
      // 取百分比
      this.percentageTime = (currentTime / durationTime) * 100
      // 当前点击位置
      circle.style.left = (this.percentageTime / 100) * wrap.offsetWidth + 'px'
      // // console.log(circle.style.left)
    },

    // 点击进度条修改播放时长
    updatePlayTime(e) {
      const wrap = document.querySelector('.wrap')
      // // console.log()
      this.percentageTime = (e.pageX / wrap.offsetWidth) * 100
      this.$refs.audio.currentTime = (this.percentageTime / 100) * this.$refs.audio.duration
    },

    // 鼠标按下事件
    moveDown(e) {
      const circle = document.querySelector('.circle')
      const wrap = document.querySelector('.wrap')

      //鼠标按下时的鼠标所在的X坐标
      this.mouseDownX = e.pageX
      // console.log(this.mouseDownX)
      circle.style.left = this.mouseDownX + 'px'
      this.percentageTime = (e.pageX / wrap.offsetWidth) * 100

      /**
       *鼠标按下时间立马改变当前播放时间
       */
      const currentTime = parseInt((this.percentageTime / 100) * this.$refs.audio.duration)
      this.currentTime = this.s_to_hs(currentTime)

      // 是否开始移动的布尔值
      this.moveFlag = true
      // 开始拖拽
      this.dragFlag = true
    },
    // 鼠标移动
    moveStart(e) {
      const circle = document.querySelector('.circle')
      const wrap = document.querySelector('.wrap')
      // 如果为真就开始移动
      if (this.moveFlag) {
        circle.style.left = e.pageX + 'px'
        this.percentageTime = (e.pageX / wrap.offsetWidth) * 100

        /**
         *鼠标移动过程时间根据移动距离改变
         */
        const currentTime = parseInt((this.percentageTime / 100) * this.$refs.audio.duration)
        this.currentTime = this.s_to_hs(currentTime)
      }
    },
    // 鼠标弹起
    moveOut() {
      this.moveFlag = false
      this.$refs.audio.currentTime = (this.percentageTime / 100) * this.$refs.audio.duration
      // 鼠标弹起将移动布尔值重新设为假

      // 拖拽布尔值
      this.dragFlag = false
    },

    // 修改音量
    updatedVolume(e) {
      const cw = document.querySelector('.column-wrap')
      // 鼠标在盒子内部点击的距离
      let columnLeft = e.pageX - cw.offsetParent.offsetLeft
      // 如果不在范围进度条范围内 就返回
      if ((columnLeft / cw.offsetWidth) * 100 < 0) return
      this.volumeNum = (columnLeft / cw.offsetWidth) * 100
      // 改变当前播放音乐声音大小
      if (this.volumeNum / 100 < 0) return
      this.$refs.audio.volume = this.volumeNum / 100
    },

    // 切换静音
    toggleMute() {
      if (this.$refs.toggleMuteRefs.classList.contains('icon-laba-fill')) {
        this.holdVolume = this.volumeNum
        this.volumeNum = 0
        this.$refs.audio.volume = this.volumeNum
        this.$refs.toggleMuteRefs.classList.remove('icon-laba-fill')
        this.$refs.toggleMuteRefs.classList.add('icon-jingyin')
      } else {
        this.volumeNum = this.holdVolume
        console.log(this.volumeNum)
        this.$refs.audio.volume = this.volumeNum / 100

        this.$refs.toggleMuteRefs.classList.remove('icon-jingyin')
        this.$refs.toggleMuteRefs.classList.add('icon-laba-fill')
      }
    },
  },
  watch: {
    // 监听循环方式变化修改颜色
    playMode(newVal) {
      // 列表循环
      const listCycle = document.querySelector('.list-cycle')
      const single = document.querySelector('.single')
      const random = document.querySelector('.random')
      if (newVal == 'listCycle') {
        listCycle.style.color = 'pink'
        single.style.color = ''
        random.style.color = ''
      } else if (newVal == 'single') {
        single.style.color = 'pink'
        listCycle.style.color = ''
        random.style.color = ''
      } else {
        random.style.color = 'pink'
        listCycle.style.color = ''
        single.style.color = ''
      }
    },

    // 监听当前播放时长
    currentTime(newVal) {
      // // console.log(newVal)
    },
  },
  data() {
    return {
      // 控制第一次布尔值
      isFlag: true,
      staticUrl: './assets/logo1.png',
      // 当前播放歌曲信息
      songInfo: '',
      // 当前播放歌曲索引
      index: '',
      // 播放器的url地址
      audioUrl: '',
      // 音乐播放时长
      durationTime: '',

      // 当前播放时长
      currentTime: '0:00',
      playList: '',

      // 播放方式
      playMode: 'listCycle',

      // 单曲循环布尔值
      playLoop: false,

      percentageTime: '',

      // 进度条是否按下
      moveFlag: false,

      // 鼠标按下当前圈在X轴位置 单位px
      mouseDownX: '',

      circleLength: '',

      // 拖拽布尔值
      dragFlag: false,

      // 歌曲音量大小
      volumeNum: 100,

      // 暂时存储音量
      holdVolume: '',

      // 音量大小颜色
      customColor: '#000',
    }
  },
}
</script>

<style lang="less" scoped>
audio {
  display: none;
}
.footer {
  display: flex;
  flex-direction: column;
  padding: 0 10vw;
  justify-content: space-evenly;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 10vh;
  background-color: rgba(253, 252, 252, 0.973);
  .actionTime {
    position: absolute;
    top: 10px;
    left: 2%;
    font-size: 15px;
    // top: 0;
  }
  .duration {
    position: absolute;
    right: 2%;
    font-size: 15px;
    top: 10px;
  }
  .bar:hover > .circle {
    display: block;
  }
  .bar {
    position: relative;
    width: 100vw;
    margin-left: -10vw;
    height: 2px;

    .wrap:hover {
      transform: scaleY(1.5);
    }

    .circle {
      cursor: pointer;
      display: none;
      // z-index: 9999;
      position: absolute;
      transform: translate(-40%, -40%);
      // left: 0%;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: rgb(235, 238, 245);
    }
    .wrap {
      z-index: 999;
      position: absolute;
      width: 100vw;
      // margin-left: -10vw;
      height: 3px;
      background-color: rgba(187, 187, 187, 0);
    }
  }
  .audio {
    // padding: 0 10px;
    display: flex;
    width: 100%;
    // background-color: aquamarine;
    justify-content: space-between;
    align-items: center;
    // height: 100%;

    .left-intro {
      width: 40%;
      display: flex;
      .content {
        width: 100%;
        padding: 1px 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        margin-left: 5px;
        h3 {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        p {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          a {
            font-size: 13px;
          }
        }
      }
      img {
        vertical-align: middle;
      }
    }
    .btn-arr {
      display: flex;
      align-items: center;
      margin: 0 auto;
      // width: 30%;
      i:hover {
        opacity: 0.7;
      }
      i {
        cursor: pointer;

        font-size: 25px;
      }
      i:nth-of-type(2) {
        margin: 0 30px;
        font-size: 35px;
        color: rgb(0, 0, 0);
      }
    }
    .play-way {
      width: 40%;
      display: flex;
      align-items: center;
      justify-content: end;
      span {
        cursor: pointer;
        margin: 10px;
        font-size: 25px;
        // color: pink;
      }
      // justify-content: space-between;
      .way-btn {
        display: flex;
      }
      .column {
        position: relative;
        .wrap {
          position: absolute;
          top: 0;
          left: 0;
          width: 100px;
          height: 3px;
          transition: all 0.3s;
          background-color: rgba(19, 216, 216, 0);
        }
        .el-progress {
          width: 100px;
          height: 3px;
          transition: all 0.3s;
        }
      }
      .column:hover > .el-progress {
        transform: scaleY(1.2);
      }
      .column:hover > .wrap {
        transform: scaleY(1.2);
      }
    }
  }
}
/deep/ .el-progress-bar__outer {
  height: 3px !important;
}
.el-progress {
  transition: all 0.3s;
}
.bar:hover > .el-progress {
  transform: scaleY(1.5) !important;
}
.el-progress-bar__outer {
  background-color: #d3d7e2;
}
</style>
