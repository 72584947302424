<template>
  <div v-if="!tableData">
    <h2>正在加载中....</h2>
  </div>
  <div v-else class="play-list">
    <!-- <h2>推荐歌单</h2> -->
    <div class="cmd-showSing">
      <img :src="tableData.coverImgUrl" alt="" />
      <div class="cmd-content">
        <h2>
          <!-- <span>歌单</span>  -->
          {{ tableData.name }}
        </h2>
        <div class="content-intro">
          <p class="first-p" v-if="tableData">
            Playlist By&nbsp;
            <!-- <img :src="avatarUrl" alt="" /> -->
            <a target="_blank" :href="userUrl">{{ nickname }} </a>
          </p>
          <!-- <div class="btn-arr">
          <el-button type="primary" round size="small"><i class="el-icon-video-play"></i>播放全部</el-button>
          <el-button round size="small"><i class="el-icon-circle-plus-outline"></i>收藏</el-button>
          <el-button round size="small"><i class="el-icon-share"></i>分享</el-button>
          <el-button round size="small"><i class="el-icon-download"></i>下载</el-button>
        </div> -->
          <!-- <p v-if="tagsFlag">
          标签:<span v-for="(item, index) in tableData.tags" :key="index">{{ index + 1 == tagsList.length ? item : item + '/' }}</span>
        </p> -->
          <p>
            创建于{{ tableData.createTime | dataFormat }} &nbsp;·&nbsp;{{ tableData.trackCount }}首歌 <span>·&nbsp;{{ tableData.playCount }}次播放</span>
          </p>
          <p>{{ tableData.description }}</p>
        </div>
        <div class="btn-arr">
          <!-- <a href="#">播放</a> -->
        </div>
      </div>
    </div>

    <div v-if="tableData" class="song-list">
      <!-- 歌曲列表 -->
      <div v-if="!allSingList"><h2>正在加载中...</h2></div>
      <div @dblclick="searchSingById(item)" class="playlist-table" v-for="item in allSingList" :key="item.id">
        <div class="left">
          <div class="img">
            <i @click="searchSingById(item)" class="el-icon-video-play"></i>
            <img style="width: 40px; height: 40px" :src="item.al.picUrl" alt="" />
          </div>
          <div class="song-name">
            <h3>
              {{ item.name }} <span v-for="item in item.al.alia" :key="item.id">({{ item }})</span>
            </h3>
            <p>
              <router-link v-for="(item, index) in item.ar" :key="index" :to="{ name: 'singerinfo', query: { id: item.id } }">{{ item.name }} </router-link>
            </p>
          </div>
        </div>
        <div class="song-album">{{ item.al.name }}</div>
        <p>
          <span>{{ item.dt | timeFormat }}</span>
        </p>
      </div>
      <!-- <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="歌曲列表" name="singleSing"> -->
      <!-- <el-table @row-dblclick="searchSingById" stripe ref="singleTable" :data="allSingList.slice((currentPage - 1) * pagesize, currentPage * pagesize)" highlight-current-row style="width: 100%">
            <el-table-column align="center" width="50" type="index">
              <template slot-scope="scope">
                <i @click="searchSingById(scope.row)" class="el-icon-video-play"></i>
              </template>
            </el-table-column>

            <el-table-column label="音乐标题" width="450">
              <template v-slot="scope">
                <div class="song-name">
                  {{ scope.row.name }}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="歌手" width="220">
              <template v-slot="scope">
                <div class="singer-name">
                  <router-link :to="{ name: 'singerinfo', query: { id: scope.row.ar[0].id } }">{{ scope.row.ar[0].name }}</router-link>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="专辑">
              <template v-slot="scope">
                <div class="song-time">
                  {{ scope.row.al.name }}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="时间" width="120">
              <template v-slot="scope">
                <div class="song-time">
                  {{ scope.row.dt | timeFormat }}
                </div>
              </template>
            </el-table-column>
          </el-table> -->
      <!-- <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[5, 10, 20, 40]" :page-size="pagesize" layout="total, sizes, prev, pager, next, jumper" :total="allSingList.length"> </el-pagination> -->
      <!-- </el-tab-pane>
        <el-tab-pane label="评论" name="comment">
          <div class="comment-box" v-for="item in commentsList" :key="item.commentId">
            <img :src="item.user.avatarUrl" alt="" />
            <div class="content-box">
              <p>
                <a href="#">{{ item.user.nickname }}：</a><span>{{ item.content }}</span>
              </p>
              <p class="publishTime">{{ item.timeStr }}<i></i></p>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs> -->
    </div>
  </div>
</template>

<script>
export default {
  props: ['id'],
  computed: {
    tagsFlag() {
      return this.tagsList.length == 0 ? false : true
    },
  },
  mounted() {
    // // // console.log(this.$router.currentRoute.query.id)
    this.keyId = this.$route.query.id
  },
  data() {
    return {
      // 路由传递id
      keyId: '',
      activeName: 'singleSing',
      tableData: '',
      avatarUrl: '',
      nickname: '',
      // 歌单所有歌曲
      allSingList: '',
      currentPage: 1, //初始页
      pagesize: 10, //    每页的数据
      // 歌单评论列表数据
      commentsList: [],
      // 歌曲标签数据
      tagsList: [],
      // 歌单创建者id
      userId: '',
      // 跳转地址
      userUrl: '',
    }
  },
  methods: {
    // 获取歌单的所有评论
    async getSangComment() {
      // // // console.log(this.keyId)
      const { data: res } = await this.$http.get(`/comment/playlist?id=${this.keyId}&limit=20`)
      // // // console.log(res)
      this.commentsList = res.comments
      // // // console.log(this.commentsList)
    },
    handleClick(tab, event) {
      // // // console.log(tab, event)
    },
    handleCurrentChange() {},

    // 通过路由传递歌单id获取歌单详情
    async getAllSingList() {
      const { data: res } = await this.$http.get(`playlist/detail?id=${this.$route.query.id}`)
      // tableData.creator.avatarUrl
      this.tableData = res.playlist
      this.tagsList = res.playlist.tags
      this.avatarUrl = res.playlist.creator.avatarUrl
      this.nickname = res.playlist.creator.nickname
      this.userUrl = 'https://music.163.com/#/user/home?id=' + res.playlist.creator.userId

      // // // console.log(res)
      // // console.log(this.tableData)
    },
    // 根据id显示歌单所有歌曲
    async getAllSing() {
      if (window.sessionStorage.getItem('cookie') == null) {
        this.$message.info('没有查询到登录信息！请登录体验完整功能！')
        const { data: res } = await this.$http.post(`/playlist/track/all?id=${this.$route.query.id}`)
        // // // console.log('我没有登录', res)
        if (res.code !== 200) return this.$message.info('获取列表失败！请查看是否登录！')
        // // console.log(res)
        this.allSingList = res.songs
        // this.allSingList.forEach((item) => {
        // const { data: res1 } = this.$http.get(`/song/detail?ids=1890675363`)
        // item.picUrl = res.picUrl
        // // // console.log(res1)
        // })
      } else {
        const { data: res } = await this.$http.post(`/playlist/track/all?id=${this.$route.query.id}`, { cookie: window.sessionStorage.getItem('cookie') })
        // const { data: res } = await this.$http.post(`/playlist/track/all?id=${this.$route.query.id}`, { withCredentials: true })
        if (res.code !== 200) return this.$message.info('获取列表失败！请查看是否登录！')
        // // console.log(res)
        // // // console.log('我登录了', res)
        this.allSingList = res.songs
      }

      // // // console.log(this.allSingList)
    },

    // 获取传进来的id歌曲信息
    async searchSingById(val) {
      // // // console.log(val.id)
      // 用post请求 在请求体中加上cookie值
      if (window.sessionStorage.getItem('cookie') == null) {
        this.$message.info('没有查询到登录信息！请登录体验完整功能！')
        const { data: res } = await this.$http.post(`/song/url?id=${val.id}`)
        // // // console.log('我没有登录', res)
        if (res.code !== 200) return this.$message.info('获取列表失败！请查看是否登录！')
        this.sangInfo = res.data
        // // // console.log(this.sangInfo)
        // 获取到歌曲信息 判断是否为会员专享和没有歌曲信息
        if (this.sangInfo[0].code != 200) return this.$message.error('该歌曲必须登录或者是会员专享！')

        // 通过全局事件总线传递数据
        // this.$bus.$emit('sendSangUrl', this.sangInfo[0].url)
        this.$bus.$emit('sendSangUrl', this.sangInfo[0].url, this.allSingList, val.id)
      } else {
        // // console.log(1)
        const { data: res } = await this.$http.post(`/song/url?id=${val.id}`, { cookie: window.sessionStorage.getItem('cookie') })
        // const { data: res } = await this.$http.post(`/song/url?id=${val.id}`, { withCredentials: true })
        // // // console.log('我登录了', res)
        if (res.code !== 200) return this.$message.info('获取列表失败！请查看是否登录！')
        this.sangInfo = res.data
        // // console.log(this.sangInfo)
        // 获取到歌曲信息 判断是否为会员专享和没有歌曲信息
        if (this.sangInfo[0].code != 200) return this.$message.error('该歌曲必须登录或者是会员专享！')

        // 通过全局事件总线传递数据
        // this.$bus.$emit('sendSangUrl', this.sangInfo[0].url)
        this.$bus.$emit('sendSangUrl', this.sangInfo[0].url, this.allSingList, val.id)
        // this.audioUrl =
      }
    },
  },
  created() {
    // // // console.log(this.$router)
    this.getAllSingList()
    this.getAllSing()
  },
  beforeDestroy() {
    // this.$bus.$off('sendSangUrl')
  },
}
</script>

<style lang="less" scoped>
.play-list {
  padding: 20px 0;
  width: 100%;
}
.song-time {
  font-size: 13px;
  color: #b0adad;
}
.song-name {
  color: black;
  font-size: 14px;
  font-weight: 600;
}
.singer-name {
  a:hover {
    color: black;
    border-bottom: 1px solid rgb(48, 63, 201);
  }
}
/deep/.el-button--primary {
  color: #fff;
  background-color: #ff85a6;
  border-color: #ff85a6;
}
/deep/.el-button:focus,
.el-button:hover {
  color: #ff85a6;
  border-color: #ffbcce;
  background-color: #ffd9e29d;
}
/deep/.el-tabs__item.is-active {
  font-size: 18px;
  font-weight: 500;
  color: #ff85a6 !important;
}
/deep/.el-tabs__item:hover {
  color: #ff85a6;
  cursor: pointer;
}
/deep/.el-tabs__active-bar {
  background-color: #ff85a6;
}
/deep/.el-tabs__nav-wrap::after {
  width: 0;
}
/deep/.el-table .el-table__cell {
  padding: 5px 0;
}
/deep/.cell {
  font-size: 13px;
  color: rgb(116, 116, 116);
  font-weight: 500;
}
/deep/.el-pagination {
  text-align: center;
  margin: 20px 0;
}
.cmd-showSing {
  display: flex;
  margin-bottom: 30px;
  height: 290px;
  img {
    // width: 100%;
    width: 290px;
    height: 100%;
    margin: 0 20px 0 0;
    box-shadow: 0 5px 20px rgba(143, 142, 142, 0.438);
  }
}
.cmd-content {
  margin-left: 20px;
  flex-grow: 1;
  // background-color: blueviolet;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  h2 {
    font-size: 35px;
    display: flex;
    align-items: center;
    // width: 60%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    span {
      display: block;
      width: 40px;
      text-align: center;
      line-height: 20px;
      height: 20px;
      font-size: 14px;
      border-radius: 3px;
      margin-right: 5px;
      font-weight: 400;
      color: rgb(255, 133, 166);
      border: 1px solid rgb(255, 133, 166);
    }
  }
  .content-intro p:nth-of-type(2) {
    font-size: 14px;
    color: rgb(119, 116, 116);
    margin: 5px 0 20px 0;
  }
  .content-intro p:nth-of-type(3) {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .btn-arr {
    margin-bottom: 10px;
  }
  .first-p {
    font-size: 19px;

    display: flex;
    // justify-content: center;
    align-items: center;
    img {
      margin-right: 4px;
    }
    a {
      color: rgb(53, 51, 51);
      margin-right: 4px;
    }
    a:hover {
      // text-decoration: blue;
      color: rgb(0, 0, 0);
      text-decoration-line: underline;
      text-decoration-thickness: initial;
      text-decoration-style: initial;
      text-decoration-color: initial;
    }
  }
  .content-intro p:nth-of-type(3) {
    font-size: 16px;
    color: rgb(66, 59, 59);
  }
  // p {
  //   font-size: 16px;
  //   img {
  //     width: 20px;
  //     height: 20px;
  //     border-radius: 50%;
  //   }
  //   a {
  //     color: rgb(77, 72, 72);
  //   }
  // }
}
.comment-box {
  height: 75px;
  padding: 0 0 10px 0;
  border-bottom: 1px solid #ccc;
  display: flex;
  // width: 0; //！！！！！！！这块需要设为0
  align-items: center;
  img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }

  .content-box {
    width: 0; //！！！！！！！这块需要设为0
    flex: 1;
    height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 3px;
    p {
      width: 100%;
      font-size: 13px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      span {
        font-size: 12px;
        font-weight: 400;
      }
    }
    .publishTime {
      font-size: 12px;
      color: #ccc;
      font-weight: 400;
    }
    a {
      color: #f95682;
    }
  }
}
.el-table {
  i {
    font-size: 15px;
    color: rgb(158, 155, 155);
    // margin-top: 5px;
  }
  .el-table__row:hover i {
    color: black;
  }
  i:hover {
    cursor: pointer;
  }
}
.song-list {
  .playlist-table:hover {
    background-color: rgba(185, 183, 183, 0.329);
  }
  .playlist-table:hover > .left > .img > i {
    display: block;
    color: rgb(214, 200, 200);
  }
  .playlist-table {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 15px;
    height: 55px;
    width: 100%;
    // border: 1px solid #ccc;
    padding: 0 10px;
    margin: 5px 0;

    .left {
      .img {
        position: relative;
        height: 40px;
        width: 40px;
        i {
          display: none;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          // display: none;
          font-size: 20px;
          color: rgb(201, 126, 167);
          // margin-top: 5px;
          margin-right: 5px;
        }

        i:hover {
          cursor: pointer;
        }
      }

      display: flex;
      align-items: center;
      width: 55%;
      .song-name {
        margin-left: 10px;
        // height: 45px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        h3 {
          width: 400px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          font-size: 17px;
          font-weight: 600;
          span {
            color: rgb(143, 138, 138);
          }
        }
        a:hover {
          color: rgba(0, 0, 0);
          text-decoration: underline;
        }
        p {
          width: 400px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          a {
            font-size: 13px;
            color: rgb(88, 88, 88);
            font-weight: 400;
          }
        }
      }
    }
    .song-album {
      font-size: 15px;
      color: rgb(30, 30, 30);
      // flex-grow: 1;
      width: 40%;
      // text-align: center;
      // margin: 0 auto;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    p:last-of-type {
      color: rgb(88, 88, 88);
      font-size: 14px;
      width: 30%;
      // flex-grow: 1;
      span {
        float: right;
      }
    }
  }
}
</style>
