<template>
  <div v-if="!artistList">
    <h2>正在加载中....</h2>
  </div>
  <div v-else class="singer-info">
    <div class="singer-top">
      <img v-lazy="artistList.img1v1Url" alt="" />
      <div class="singer-details">
        <h2>{{ artistList.name }}</h2>
        <p class="english-name">{{ alias }}</p>
        <div class="songs-info">
          <p>单曲数:{{ artistList.musicSize }}</p>
          <p>专辑:{{ artistList.albumSize }}</p>
          <p>MV:{{ artistList.mvSize }}</p>
        </div>
        <p class="singer-intro" @click="showSingerIntro">{{ artistList.briefDesc }}</p>
      </div>
    </div>
    <h2>热门歌曲</h2>
    <!-- 歌手热门歌曲 -->
    <div class="hot-songs">
      <div @dblclick="searchSingById(item)" class="hotSong-list" v-for="item in singerHotSongsList" :key="item.id">
        <div class="info">
          <img v-lazy="item.al.picUrl" alt="" />
          <div class="h3">
            <h3>
              {{ item.name }} <span v-for="item in item.al.alia" :key="item.id">({{ item }})</span>
            </h3>

            <p>
              <router-link :to="{ name: 'singerinfo', query: { id: k.id } }" v-for="(k, index) in item.ar" :key="index"> {{ k.name }}</router-link>
            </p>
          </div>
        </div>
        <i @click="searchSingById(item)" class="el-icon-video-play"></i>
      </div>
    </div>

    <h2 v-if="singerMvList">MVs</h2>
    <!-- 歌手热门MV -->
    <div class="hot-MVs">
      <div class="hotMv-list" v-for="item in singerMvList" :key="item.id">
        <div class="img">
          <img @click="showMvDialog(item.id)" v-lazy="item.imgurl" alt="" />
          <i class="el-icon-video-play"></i>
        </div>
        <div class="info">
          <h4>
            {{ item.name }}
            <!-- <span v-for="item in item.al.alia" :key="item.id">({{ item }})</span> -->
          </h4>
          <p>{{ item.publishTime }}</p>
        </div>
      </div>
    </div>

    <!-- 歌手介绍详情框 -->
    <el-dialog :visible.sync="singerIntroDialogVisible" width="50%" :close="singerIntroClose">
      <span>{{ artistList.briefDesc }}</span>
    </el-dialog>
    <!-- 歌手mv -->
    <el-dialog class="mv-dialog" :visible.sync="mvDialogVisible" :close-on-click-modal="false" @close="closeEvent" width="50%">
      <video v-if="mvDialogVisible" autoplay :src="mvInfo.url" controls></video>
    </el-dialog>
  </div>
</template>

<script>
export default {
  watch: {
    $route(to, from) {
      this.getUserSongs(to.query)
      this.getSingerMv(to.query)
    },
  },
  data() {
    return {
      // 歌手所有信息
      singerInfo: [],
      // 歌手介绍
      artistList: '',
      // 歌手详情展示框
      singerIntroDialogVisible: false,
      // 歌手mv
      mvDialogVisible: false,
      // 歌手热门歌曲
      singerHotSongsList: [],

      alias: '',
      // 歌手名字
      artistName: '',
      // 歌手相关mv数据
      singerMvList: {},
      // mv数据
      mvInfo: [],
    }
  },
  mounted() {
    // // // console.log(this.$route.query.id)
    // this.getSingerInfo()
    this.getUserSongs()
    this.getSingerMv()
  },
  methods: {
    // 通过路由参数传来的歌手id获取歌手详情信息
    // async getSingerInfo() {
    //   const { data: res } = await this.$http.get(`/artist/detail?id=${this.$route.query.id}`)
    //   if (res.code !== 200) return this.$message.error('获取歌手信息失败，请刷新重试！')
    //   // // // console.log(res)
    //   this.singerInfo = res.data
    //   this.artistList = res.data.artist
    //   // // // console.log(this.singerInfo)
    // },
    async searchSingById(val) {
      // // // console.log(val)
      // 用post请求 在请求体中加上cookie值
      if (window.sessionStorage.getItem('cookie') == null) {
        this.$message.info('获取列表失败！请查看是否登录！')
        const { data: res } = await this.$http.post(`/song/url?id=${val.id}`)
        // // // console.log(res)
        // // // console.log('我没登陆听的歌手歌曲', res)
        if (res.code !== 200) return this.$message.info('获取列表失败！请查看是否登录！')
        this.sangInfo = res.data
        // // // console.log(this.sangInfo)
        // 获取到歌曲信息 判断是否为会员专享和没有歌曲信息
        if (this.sangInfo[0].code != 200) return this.$message.error('该歌曲必须登录或者是会员专享！')

        // 通过全局事件总线传递数据
        this.$bus.$emit('sendSangUrl', this.sangInfo[0].url)
        // this.audioUrl =
      } else {
        const { data: res } = await this.$http.post(`/song/url?id=${val.id}`, { cookie: window.sessionStorage.getItem('cookie') })
        // // // console.log(res)
        // // // console.log('我登陆了听的歌手歌曲', res)
        if (res.code !== 200) return this.$message.info('获取列表失败！请查看是否登录！')
        this.sangInfo = res.data
        // // // console.log(this.sangInfo)
        // 获取到歌曲信息 判断是否为会员专享和没有歌曲信息
        if (this.sangInfo[0].code != 200) return this.$message.error('该歌曲必须登录或者是会员专享！')

        // 通过全局事件总线传递数据
        this.$bus.$emit('sendSangUrl', this.sangInfo[0].url, this.singerHotSongsList, val.id)
        // this.audioUrl =
      }
    },
    // 获取歌手热门歌曲
    async getUserSongs() {
      const { data: res } = await this.$http.get(`/artists?id=${this.$route.query.id}`)
      // console.log(res)
      if (res.code !== 200) return this.$message.error('获取歌手信息失败，请刷新重试！')
      this.artistList = res.artist
      // if (res.artist.alias.length == 0) {
      //   // return
      // } else {
      //   this.alias = res.artist.alias[0]
      // }
      this.singerHotSongsList = res.hotSongs
      // // // console.log(this.artistName)
    },

    // 获取歌手相关MV
    async getSingerMv() {
      // 获取歌手的热门mv
      // // console.log(this.artistName)
      const { data: res } = await this.$http.get(`/artist/mv?id=${this.$route.query.id}`)
      // // console.log(res)
      if (res.mvs.length == 0) {
        this.singerMvList = ''
      } else {
        this.singerMvList = res.mvs
      }
    },

    // 点击展示歌手详情介绍
    showSingerIntro() {
      this.singerIntroDialogVisible = true
    },

    // 歌手详情介绍对话框关闭事件
    singerIntroClose() {},

    // 点击mv盒子播放
    async showMvDialog(id) {
      this.$bus.$emit('pauseSang')
      this.mvDialogVisible = true
      const { data: res } = await this.$http.get('/mv/url?id=' + id)
      // // // console.log(res)
      this.mvInfo = res.data
    },

    // 关闭盒子事件
    closeEvent() {
      this.$bus.$emit('actionSang')
      this.mvDialogVisible = false
    },
  },
}
</script>

<style lang="less" scoped>
/deep/.el-dialog__header,
/deep/.el-dialog__body {
  color: rgb(0, 0, 0);
  font-size: 18px;
  font-weight: 500;
  background-color: rgb(255, 255, 255) !important;
  // border-radius: 20px !important;
}
/deep/.mv-dialog .el-dialog__header,
/deep/.mv-dialog .el-dialog__body {
  color: rgb(228, 228, 228);
  font-size: 18px;
  font-weight: 500;
  background-color: rgb(0, 0, 0) !important;
  // border-radius: 20px !important;
}
i {
  font-size: 16px;
  color: rgb(158, 155, 155);
  // margin-top: 5px;
}
.el-table__row:hover i {
  color: black;
}
i:hover {
  cursor: pointer;
}
.list-box {
  display: flex;

  .el-table {
    flex-grow: 1;
    margin-left: 50px;
    .song-time {
      font-size: 13px;
      color: #b0adad;
    }
    .song-name {
      color: black;
      font-size: 15px;
      font-weight: 600;
    }
    .singer-name {
      a {
        color: rgb(59, 54, 119);
      }
      a:hover {
        color: black;
        border-bottom: 1px solid rgb(48, 63, 201);
      }
    }
  }
}
.singer-info {
  // height: 220px;
  // width: 220px;

  .singer-top {
    margin: 20px 0 30px 0;
    display: flex;
    align-items: center;
    height: 300px;
    img {
      width: 250px;
      height: 250px;
      border-radius: 50%;
      box-shadow: 0 10px 10px rgb(143, 140, 140);
    }
    .singer-details {
      margin-left: 20px;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      h2 {
        font-size: 50px;
      }
      .songs-info {
        margin: 15px 0 10px 0;
        font-size: 14px;
        display: flex;
        color: rgb(130, 130, 130);
        p {
          margin-right: 5px;
        }
      }
      .english-name {
        margin: 15px 0;
      }
      .singer-intro:hover {
        color: black;
        cursor: pointer;
      }
      .singer-intro {
        color: rgb(148, 141, 141);
        font-size: 14px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        color: rgb(130, 130, 130);
      }
    }
  }

  .hot-songs {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    // display: grid;
    // gap: 4px;
    // grid-template-columns: repeat(3, 1fr);
    .hotSong-list:hover {
      background-color: rgba(201, 194, 194, 0.637);
    }
    .hotSong-list:hover i {
      color: rgb(2, 2, 2);
    }
    .hotSong-list {
      margin: 5px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px;
      // height: 60px;
      width: 32%;
      border-radius: 10px;
      // border: 1px solid #ccc;
      transition: all 0.5s;
      .info {
        display: flex;
        // flex-direction: column;

        // flex-grow: 1;
        img {
          width: 40px;
          height: 40px;
          vertical-align: middle;
          margin-right: 10px;
        }
        .h3 {
          // width: 300px;
          width: 240px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          // align-items: center;

          h3 {
            width: 100%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-size: 17px;
          }
          p {
            // width: 270px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-size: 13px;
          }
          span {
            font-size: 16px;
            color: rgb(102, 101, 101);
          }
        }
      }
      i:hover {
        opacity: 0.5;
      }
      i {
        transition: all 0.2s;
        font-size: 18px;
        color: rgba(204, 204, 204, 0);
      }
    }
  }
  .hot-MVs {
    // margin-bottom: 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .hotMv-list:hover img {
      box-shadow: 0 10px 20px rgb(145, 142, 142);
    }
    .hotMv-list {
      // height: 230px;
      width: 18%;
      margin: 10px 0;
      .img:hover i {
        font-size: 40px;
        color: rgb(206, 205, 205);
      }
      .img {
        position: relative;
        img {
          cursor: pointer;
          height: 150px;
          width: 100%;
          transition: all 0.3s;
        }
        i {
          // display: none;
          position: absolute;
          top: 50%;
          left: 50%;
          color: rgba(255, 255, 255, 0);
          font-size: 10px;
          transform: translate(-50%, -50%);
          transition: all 0.2s;
        }
      }
      h4 {
        font-size: 14px;
        // width: 18%;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        display: box;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      p {
        font-size: 12px;
        color: rgb(158, 158, 158);
      }
    }
  }
}
.singer-info > h2 {
  margin: 20px 0;
}
video {
  width: 100%;
  height: 100%;
}
.video {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .video-small {
    position: relative;
    display: flex;
    width: 275px;
    height: 250px;
    border-radius: 5px;
    // overflow: hidden;
    flex-direction: column;

    .video-info {
      // width: 0;
      // flex: 1;
      p {
        width: 100%;
        // flex: 1;
        font-weight: normal;
        // width: 270px;
      }
      .video-keywords {
        font-size: 14px;
        // display: flex;
        width: 100%;
        // max-width: 280px;
        align-items: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 2px 0px 3px 0;
        span {
          font-size: 12px;
          display: inline-block;
          border: 1px solid #ff85a6;
          color: #ff85a6;
          font-weight: 600;
          border-radius: 3px;
          margin-right: 3px;
        }
      }
    }
    .video-img {
      cursor: pointer;
      position: relative;
      // height: 180px;
      .screen {
        position: absolute;
        top: 0;
        right: 5px;
        color: white;
        font-size: 13px;
      }
      .time-screen {
        position: absolute;
        bottom: 6px;
        right: 5px;
        color: white;
        font-size: 13px;
      }
      img {
        width: 100%;
        height: 180px;
      }
    }
  }
}
</style>
