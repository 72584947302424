<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {}
</script>

<style lang="less">
/deep/.el-table .cell {
  line-height: 20px !important;
}
.level {
  ul {
    li {
      display: flex;
      align-items: center;
      span {
        color: #eb7484;
        width: 150px;
      }
    }
  }
}
.show-box {
  display: flex;
  justify-content: space-around;
  border-bottom: 1px solid #ccc;
  margin-bottom: 10px;
  h3 {
    text-align: center;
    cursor: pointer;
  }
  p {
    font-size: 12px;
  }
}
img {
  width: 220px;
  height: 220px;
  border-radius: 10px;
}
body,
html {
  // font-family: 'Helvetica Neue', Helvetica, 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', '微软雅黑', Arial, sans-serif;
  height: 100%;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
ul,
ol {
  list-style: none;
}
a {
  text-decoration: none;
  color: rgb(0, 0, 0);
  // font-size: 15px;
}
a:hover {
  text-decoration: underline;
}
.active {
  background-color: #f7eeeea8;
  font-weight: 600;
  // border-right: 3px solid #ee9ca7;
}
.line {
  border-bottom: 2px solid #ee9ca7;
}
</style>
