<template>
  <!-- <div v-if="!sangUserInfo">
    <h2>正在加载中....</h2>
  </div> -->
  <div class="search">
    <div class="top-box" v-if="sangUserInfo">
      <div class="singer">
        <h2 v-if="sangUserInfo">艺人</h2>
        <div class="singer-box">
          <div class="singer-img" v-for="item in sangUserInfo" :key="item.id">
            <router-link :to="{ name: 'singerinfo', query: { id: item.id } }">
              <img :src="item.picUrl" alt="" />
              <h3>{{ item.name }}</h3>
            </router-link>
          </div>
        </div>
      </div>
      <div class="album">
        <h2 v-if="albumsInfo">专辑</h2>
        <div class="album-box">
          <div class="album-img" v-for="item in albumsInfo" :key="item.id">
            <img :src="item.picUrl" alt="" />
            <h3>{{ item.name }}</h3>
          </div>
        </div>
      </div>
    </div>
    <!-- 关键词歌曲 -->
    <div class="songs">
      <h2 v-if="songsInfo">歌曲</h2>
      <!-- 歌手热门歌曲 -->
      <div class="hot-songs">
        <div @dblclick="searchSingById(item)" class="hotSong-list" v-for="item in songsInfo" :key="item.id">
          <img :src="item.al.picUrl" alt="" />
          <div class="info">
            <h3>{{ item.name }}</h3>
            <p>
              <router-link v-for="(k, index) in item.ar" :key="index" :to="{ name: 'singerinfo', query: { id: k.id } }">{{ k.name }} </router-link>
            </p>
          </div>
          <i @click="searchSingById(item)" class="el-icon-video-play"></i>
        </div>
      </div>
      <!-- <h2>热门歌曲</h2>
      歌手热门歌曲
      <div class="hot-songs">
        <div @dblclick="searchSingById(item)" class="hotSong-list" v-for="item in singerHotSongsList" :key="item.id">
          <img v-lazy="item.al.picUrl" alt="" />
          <div class="info">
            <h3>
              {{ item.name }}
              <span v-for="item in item.al.alia" :key="item.id">({{ item }})</span>
            </h3>
            <p>{{ artistName }}</p>
          </div>
        </div>
      </div> -->
    </div>

    <!-- 关键词MV -->
    <div class="MVs">
      <h2 v-if="mvsInfo">MV</h2>
      <!-- 歌手热门MV -->
      <div class="hot-MVs">
        <div class="Mv-list" v-for="item in mvsInfo" :key="item.id">
          <div class="img"><img @click="showMvDialog(item.id)" :src="item.cover" alt="" /> <i class="el-icon-video-play"></i></div>

          <div class="mv-info">
            <h3>{{ item.name }}</h3>
            <router-link :to="{ name: 'singerinfo', query: { id: item.artistId } }">
              <p>{{ item.artistName }}</p>
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <!-- 关键词歌单 -->
    <div class="playlists">
      <h2 v-if="playlistInfo">歌单</h2>
      <!-- 歌手热门歌单-->
      <div class="hot-playlist">
        <div class="play-list" v-for="item in playlistInfo" :key="item.id">
          <router-link :to="{ path: '/doc/recommend', query: { id: item.id } }">
            <img :src="item.coverImgUrl" alt="" />
            <div class="playlist-info">
              <h3>{{ item.name }}</h3>
            </div>
          </router-link>
        </div>
      </div>
    </div>
    <el-dialog :visible.sync="mvDialogVisible" :close-on-click-modal="false" @close="closeEvent" width="50%">
      <video v-if="mvDialogVisible" autoplay :src="mvInfo.url" controls></video>
    </el-dialog>
  </div>
</template>

<script>
export default {
  mounted() {
    // this.$bus.$on('sendUserList', this.getUserList)
    // this.$bus.$on('getSangList', this.getUserList)
    // // // console.log(this.$router.currentRoute.query.key)
    this.keyId = this.$route.query.key
    // 歌手信息
    this.getUserList(this.$route.query.key)
    // 歌曲信息
    this.getSongsInfo(this.$route.query.key)
    // 专辑信息
    this.getAlbumsInfo(this.$route.query.key)
    // Mv信息
    this.getMvsInfo(this.$route.query.key)
    // 歌单信息
    this.getPlayListInfo(this.$route.query.key)
    // // // console.log()
    // // // console.log(this.$router.currentRoute.query.key)
  },
  updated() {
    // // // console.log(this.$router.currentRoute.query.key)
  },
  data() {
    return {
      // 关键词搜索到的歌手信息
      sangUserInfo: '',

      // 关键词搜索到的专辑信息
      albumsInfo: '',

      // 关键词搜索到的专辑信息
      songsInfo: '',

      // 关键词搜索到的MV信息
      mvsInfo: '',

      // 挂见此搜索到的歌单信息
      playlistInfo: '',

      sangList: {},
      // 歌手列表框是否展示布尔值
      isFlag: false,
      // 双击歌曲行获取歌曲所有数据
      sangInfo: [],
      // 获取第一首歌曲id
      firstSangId: '',
      // 热门歌曲评论列表信息
      commentsList: [],

      keyId: '',

      // 关键词对应的MV列表
      wordsMvList: '',

      // 控制mv展示的布尔值
      mvDialogVisible: false,

      // mv信息
      mvInfo: '',
    }
  },
  watch: {
    $route(to, from) {
      //将mounted中的数据在这里重新加载一下即可
      // // // console.log(to, from)
      // // // console.log(this.$route)
      // // // console.log(to.query.key)
      this.keyId = to.query.key
      if (!from.query.key) return
      this.activeName = 'singleSing'
      this.getUserList(to.query.key)
    },
    keyId(val) {
      // 歌手信息
      this.getUserList(val)
      // 歌曲信息
      this.getSongsInfo(val)
      // 专辑信息
      this.getAlbumsInfo(val)
      // Mv信息
      this.getMvsInfo(val)
      // 歌单信息
      this.getPlayListInfo(val)
    },
    // mvDialogVisible(val) {
    //   // // // console.log(val)
    //   if (val == 'false') {
    //     // let video = document.getElementById('video')
    //     // video.ended()
    //     this.$refs.vueMiniPlayer.pause()
    //   }
    // },
  },

  methods: {
    // 获取歌手信息
    async getUserList(val) {
      // 根据关键词搜索到的歌手信息
      const { data: res } = await this.$http.post(`/search?keywords=${this.$route.query.key}&type=100&limit=3`)

      // // console.log(res)

      if (res.result.artists.length == 0) return (this.sangUserInfo = '')

      // 判断orders的长度如果为0 就没有歌手信息 不展示歌手列表框
      if (res.code !== 200) return this.$message.info('请先登录！')
      // 歌手信息
      this.sangUserInfo = res.result.artists
    },

    // 获取歌曲信息
    async getSongsInfo(val) {
      // 根据关键词搜索到的歌曲信息
      const { data: res } = await this.$http.post(`/cloudsearch?keywords=${this.$route.query.key}&type=1&limit=16`)

      if (res.code !== 200) return this.$message.info('请先登录！')

      // 歌曲信息
      this.songsInfo = res.result.songs
    },

    // 获取专辑信息
    async getAlbumsInfo() {
      // 根据关键词搜索专辑信息
      const { data: res } = await this.$http.post(`/search?keywords=${this.$route.query.key}&type=10&limit=3`)

      // // console.log(res)
      if (res.result.albums.length == 0) return (this.albumsInfo = '')

      if (res.code !== 200) return this.$message.info('请先登录！')

      // 专辑信息
      this.albumsInfo = res.result.albums
    },

    // 获取Mv信息
    async getMvsInfo() {
      // 根据关键词搜索MV信息
      const { data: res } = await this.$http.post(`/search?keywords=${this.$route.query.key}&type=1004&limit=5`)

      if (res.code !== 200) return this.$message.info('请先登录！')

      // Mv信息
      this.mvsInfo = res.result.mvs
    },

    // 获取歌单信息
    async getPlayListInfo() {
      // 根据关键词搜索歌单信息
      const { data: res } = await this.$http.post(`/search?keywords=${this.$route.query.key}&type=1000&limit=12`)

      if (res.code !== 200) return this.$message.info('请先登录！')
      // console.log(res)

      if (res.result.playlists.length == 0) return (this.playlistInfo = '')
      // 歌单信息
      this.playlistInfo = res.result.playlists
    },

    // 获取传进来的id歌曲信息
    async searchSingById(val) {
      // // // console.log(val.id)
      // 用post请求 在请求体中加上cookie值
      if (window.sessionStorage.getItem('cookie') == null) {
        this.$message.info('获取列表失败！请查看是否登录！')
        const { data: res } = await this.$http.post(`/song/url?id=${val.id}`)
        // // // console.log(res)
        // // // console.log('我没登陆听搜索到的', res)
        if (res.code !== 200) return this.$message.info('获取列表失败！请查看是否登录！')
        this.sangInfo = res.data
        // // // console.log(this.sangInfo)
        // 获取到歌曲信息 判断是否为会员专享和没有歌曲信息
        if (this.sangInfo[0].code != 200) return this.$message.error('该歌曲必须登录或者是会员专享！')

        // 通过全局事件总线传递数据
        this.$bus.$emit('sendSangUrl', this.sangInfo[0].url)
        // this.audioUrl =
      } else {
        const { data: res } = await this.$http.post(`/song/url?id=${val.id}`, { cookie: encodeURIComponent(window.sessionStorage.getItem('cookie')) })
        // // // console.log('我登陆了听搜索到的', res)
        if (res.code !== 200) return this.$message.info('获取列表失败！请查看是否登录！')
        this.sangInfo = res.data
        // console.log(this.songsInfo)
        // 获取到歌曲信息 判断是否为会员专享和没有歌曲信息
        if (this.sangInfo[0].code != 200) return this.$message.error('该歌曲必须登录或者是会员专享！')

        // 通过全局事件总线传递数据
        this.$bus.$emit('sendSangUrl', this.sangInfo[0].url, this.songsInfo, val.id)
        // this.audioUrl =
      }
    },

    // 点击tabs切换事件
    handleClick() {
      // // // console.log(this.activeName)
      // if (this.commentsList) return
      // this.getSangComment()
    },

    // 获取第一首歌曲评论信息
    async getSangComment() {
      // // // console.log(this.firstSangId)
      const { data: res } = await this.$http.get(`comment/music?id=${this.firstSangId}&limit=20`)
      this.commentsList = res.comments
      // // // console.log(this.commentsList)
    },

    // 获取歌手的热门mv
    async getSangMv() {
      const { data: res } = await this.$http.get(`/search?keywords=${this.keyId}&type=1004&limit=100`)
      // // // console.log(res)
      this.wordsMvList = res.result
    },

    // 点击mv盒子打开对话框
    async showMvDialog(id) {
      this.$bus.$emit('pauseSang')
      // // // console.log(id)
      this.mvDialogVisible = true
      const { data: res } = await this.$http.get('/mv/url?id=' + id)
      // // // console.log(res)
      this.mvInfo = res.data
    },

    // 关闭mv盒子事件
    closeEvent() {
      // console.log(1)
      this.mvDialogVisible = false
      this.$bus.$emit('actionSang')

      // this.mvInfo = []
    },
  },
  computed: {
    showTitle() {
      if (this.activeName == 'singleSing') {
        this.toggleWords = '首单曲'
        return this.sangList.songCount
      } else if (this.activeName == 'comment') {
        this.toggleWords = '条评论'
        return this.commentsList.length
      } else {
        this.toggleWords = '个MV'
        return this.wordsMvList.mvCount
      }
    },
  },
  // watch: {},
  beforeDestroy() {
    // this.$bus.$off('sendSangUrl')
  },
}
</script>

<style lang="less" scoped>
// .singer-link {
//   display: block;
// }
video {
  width: 100%;
  height: 60vh;
}
/deep/.el-dialog__header,
/deep/.el-dialog__body {
  background-color: rgb(0, 0, 0) !important;
}
// .song-time {
//   font-size: 13px;
//   color: #b0adad;
// }
// .song-name {
//   color: black;
//   font-size: 14px;
//   font-weight: 600;
// }
// .singer-name {
//   a {
//     color: rgb(59, 54, 119);
//   }
//   a:hover {
//     color: black;
//     border-bottom: 1px solid rgb(48, 63, 201);
//   }
// }
// .el-dialog__header,
// .el-dialog__body {
//   background-color: black;
// }
// /deep/.el-button--primary {
//   color: #fff;
//   background-color: #ff85a6;
//   border-color: #ff85a6;
// }
// /deep/.el-button:focus,
// .el-button:hover {
//   color: #ff85a6;
//   border-color: #ffbcce;
//   background-color: #ffd9e29d;
// }
// /deep/.el-tabs__item.is-active {
//   font-size: 18px;
//   font-weight: 500;
//   color: #ff85a6 !important;
// }
// /deep/.el-tabs__item:hover {
//   color: #ff85a6;
//   cursor: pointer;
// }
// /deep/.el-tabs__active-bar {
//   background-color: #ff85a6;
// }
// /deep/.el-tabs__nav-wrap::after {
//   width: 0;
// }
// /deep/.el-table .el-table__cell {
//   padding: 5px 0;
// }
// /deep/.cell {
//   font-size: 13px;
//   color: rgb(116, 116, 116);
//   font-weight: 500;
// }
// .search {
//   video {
//     width: 100%;
//     height: 100%;
//   }
//   .video {
//     display: flex;
//     flex-wrap: wrap;
//     justify-content: space-between;
//     .video-small {
//       position: relative;
//       display: flex;
//       width: 275px;
//       height: 250px;
//       border-radius: 5px;
//       // overflow: hidden;
//       flex-direction: column;

//       .video-info {
//         // width: 0;
//         // flex: 1;
//         p {
//           width: 100%;
//           // flex: 1;
//           font-weight: normal;
//           // width: 270px;
//         }
//         .singer {
//           color: rgba(80, 125, 175);
//           font-size: 12px;
//           a {
//             color: rgb(59, 54, 119);
//           }
//           a:hover {
//             color: black;
//             border-bottom: 1px solid rgb(48, 63, 201);
//           }
//         }
//         .video-keywords {
//           // display: flex;
//           width: 100%;
//           // max-width: 280px;
//           align-items: center;
//           white-space: nowrap;
//           overflow: hidden;
//           text-overflow: ellipsis;
//           margin: 2px 0px;
//           span {
//             display: inline-block;
//             border: 1px solid #ff85a6;
//             color: #ff85a6;
//             font-weight: 600;
//             border-radius: 5px;
//             margin-right: 3px;
//           }
//         }
//       }
//       .video-img {
//         cursor: pointer;
//         position: relative;
//         // height: 180px;
//         .screen {
//           position: absolute;
//           top: 0;
//           right: 5px;
//           color: white;
//           font-size: 13px;
//         }
//         .time-screen {
//           position: absolute;
//           bottom: 6px;
//           right: 5px;
//           color: white;
//           font-size: 13px;
//         }
//         img {
//           width: 100%;
//           height: 180px;
//         }
//       }
//     }
//   }
//   .comment-box {
//     height: 75px;
//     padding: 0 0 10px 0;
//     border-bottom: 1px solid #ccc;
//     display: flex;
//     align-items: center;
//     img {
//       width: 40px;
//       height: 40px;
//       border-radius: 50%;
//     }
//     .content-box {
//       flex-flow: 1;
//       height: 40px;
//       display: flex;
//       flex-direction: column;
//       justify-content: space-between;
//       margin-left: 3px;
//       p {
//         font-size: 13px;
//         span {
//           font-size: 12px;
//           font-weight: 400;
//         }
//       }
//       .publishTime {
//         font-size: 12px;
//         color: #ccc;
//         font-weight: 400;
//       }
//       a {
//         color: rgb(255, 133, 166);
//       }
//     }
//   }
//   .sang-name {
//     color: rgb(255, 133, 166);
//   }
//   h3 {
//     margin-bottom: 10px;
//     span {
//       margin: 0 2px;
//       color: rgb(255, 133, 166);
//     }
//   }
//   p {
//     font-size: 12px;
//     color: rgb(75, 75, 75);
//     font-weight: 600;
//   }
//   ul {
//     margin: 20px 0;
//     display: flex;
//     li {
//       margin-right: 30px;
//       font-size: 16px;
//       a {
//         color: black;
//       }
//     }
//   }
//   .singer-box {
//     cursor: pointer;
//     // width: 350px;
//     height: 100px;
//     display: flex;
//     // border: 1px solid #ccc;
//     align-items: center;
//     border-radius: 5px;
//     margin: 15px 0;
//     background-color: rgba(238, 238, 238, 0.61);
//     img {
//       width: 100px;
//       height: 100%;
//       border-radius: 5px;
//     }
//     p {
//       display: flex;
//       position: relative;
//       // justify-content: space-between;
//       // flex-direction: row;
//       align-items: center;
//       flex-grow: 1;
//       font-size: 14px;
//       margin-left: 15px;
//       i {
//         // flex-direction: ;
//         // flex-direction: row;
//         // align-self: flex-end;
//         position: absolute;
//         right: 15px;
//       }
//       span {
//         // flex-grow: ;
//         color: rgb(94, 94, 94);
//         font-weight: 400;
//         margin-left: 10px;
//       }
//     }
//   }
// }
// i {
//   font-size: 15px;
//   color: rgb(158, 155, 155);
//   // margin-top: 5px;
// }
// .el-table__row:hover i {
//   color: black;
// }
// i:hover {
//   cursor: pointer;
// }
.search {
  .top-box {
    display: flex;
    height: 300px;
    // background-color: rgb(158, 155, 155);
    .singer {
      display: flex;
      justify-content: space-evenly;
      flex-direction: column;
      width: 50%;
      .singer-box {
        // flex-grow: 1;
        display: flex;
        // height: 300px;
        justify-content: space-between;
        // background-color: rgb(75, 75, 75);
        .singer-img:hover img {
          box-shadow: 0 5px 30px #ccc;
        }
        .singer-img {
          img {
            width: 160px;
            height: 160px;
            border-radius: 50%;
            transition: all 0.3s;
          }
          h3 {
            font-size: 16px;
            text-align: center;
            width: 160px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            text-align: center;
          }
        }
      }
    }
    .album {
      // width: 600px;
      margin-left: 100px;
      width: 50%;
      display: flex;
      justify-content: space-evenly;
      flex-direction: column;
      .album-box {
        display: flex;
        // height: 300px;
        justify-content: space-between;
        .album-img {
          img {
            width: 160px;
            height: 160px;
            border-radius: 10px;
          }
          h3 {
            font-size: 16px;
            width: 160px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            text-align: center;
          }
        }
      }
    }
  }
}
.songs {
  margin: 40px 0;
}
.hot-songs {
  margin-top: 15px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  // display: grid;
  // gap: 4px;
  // grid-template-columns: repeat(4, 1fr);
  .hotSong-list:hover {
    background-color: rgba(201, 194, 194, 0.637);
  }
  .hotSong-list {
    margin: 5px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    height: 50px;
    width: 23%;
    border-radius: 10px;
    // border: 1px solid #ccc;
    transition: all 0.6s;
    img {
      width: 35px;
      height: 35px;
      margin-right: 10px;
    }
    .info {
      display: flex;
      flex-direction: column;
      width: 180px;
      h3 {
        font-size: 15px;

        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        span {
          color: rgb(143, 138, 138);
        }
      }
      p {
        font-size: 12px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
    i {
      font-size: 20px;
      cursor: pointer;
      color: white;
      transition: all 0.5s;
    }
  }
  .hotSong-list:hover i {
    color: black;
    // font-size: 18px;
  }
}

.MVs {
  margin: 40px 0;
  width: 100%;
  display: flex;
  // background-color: rgb(143, 138, 138);
  flex-direction: column;
  .hot-MVs {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .Mv-list:hover img {
      box-shadow: 0 10px 20px rgb(145, 142, 142);
    }

    .Mv-list {
      cursor: pointer;
      width: 17%;
      .img:hover i {
        font-size: 40px;
        color: rgb(206, 205, 205);
      }
      .img {
        position: relative;
        img {
          width: 100%;
          height: 150px;
          transition: all 0.3s;
        }
        i {
          // display: none;
          position: absolute;
          top: 50%;
          left: 50%;
          color: rgba(197, 189, 189, 0.603);
          font-size: 0px;
          transform: translate(-50%, -50%);
          transition: all 0.2s;
        }
      }

      .mv-info {
        h3 {
          font-size: 16px;
          // width: 200px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        p {
          font-size: 13px;
        }
      }
    }
  }
}

.playlists {
  margin: 40px 0;
  width: 100%;
  display: flex;
  // background-color: rgb(143, 138, 138);
  flex-direction: column;
  .hot-playlist {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .play-list:hover img {
      box-shadow: 0 5px 30px rgb(145, 142, 142);
    }
    .play-list {
      width: 15%;
      margin: 10px 0;
      img {
        width: 100%;
        height: 180px;
        transition: all 0.3s;
      }
      .playlist-info:hover {
        text-decoration: underline;
      }
      .playlist-info {
        color: black;
        h3 {
          font-size: 14px;
          // width: 180px;
          //超过两行省略号
          overflow: hidden;
          text-overflow: ellipsis;
          display: box;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        p {
          font-size: 13px;
        }
      }
    }
  }
}
</style>
