<template>
  <div class="home">
    <Top></Top>
    <div class="main">
      <!-- <Aside></Aside> -->
      <Main></Main>
    </div>
    <Footer></Footer>
    <!-- 右上角弹窗信息 -->
    <!-- <div class="info"><el-button class="info-btn" plain @click="open1">1</el-button></div> -->
    <!-- <div class="info"><el-button class="info-btn" plain @click="open2">2</el-button></div> -->
  </div>
</template>

<script>
import Top from '../components/Top.vue'
import Aside from '../components/Aside.vue'
import Main from '../components/Main.vue'
import Footer from '../components/Footer.vue'
export default {
  components: {
    Top,
    Aside,
    Main,
    Footer,
  },
  data() {
    return {
      isFlag: sessionStorage.getItem('UserId') ? true : false,
    }
  },
  methods: {
    open1() {
      const h = this.$createElement
      this.$notify.info({
        title: '提示',
        dangerouslyUseHTMLString: true,
        message: '<strong>项目还处于测试中，如有bug欢迎探讨227885202@qq.com<br/>开始听歌体验吧！源码：<a style="color:rgb(252, 94, 136)" target="_blank" href="https://gitee.com/yu-zhihao1/demo_music"><i>源码地址</i></a> </strong>',
        offset: 100,
      })
    },
    // open2() {
    //   this.$notify({
    //     title: '成功',
    //     dangerouslyUseHTMLString: true,
    //     message: '<strong>恭喜您！登陆成功，请体验！</strong>',
    //     type: 'success',
    //     offset: 100,
    //   })
    // },
  },
  mounted() {
    // if (this.isFlag) {
    //   this.open2()
    // } else {
    this.open1()
    // }
  },
  watch: {},
}
</script>

<style lang="less" scoped>
/deep/.el-scrollbar__wrap {
  overflow-y: scroll;
  width: 101%;
  height: 120%;
}
/deep/.el-scrollbar__bar {
  opacity: 1;
}
/deep/.el-notification.right {
  top: 50px !important;
}
.info-btn {
  // z-index: 9999;
  display: none;
}
.home {
  // display: flex;
  // flex-direction: column;
  // height: 100vh;
  // overflow-y: scroll;
  padding: 10vh 0;
  .main {
    // height: 100vh;
    padding: 0 10vw;
  }
}
</style>
