import Vue from 'vue'
import VueRouter from 'vue-router'
import Doc from '../views/Doc.vue'
// import Home from '../views/Home.vue'
import Newsong from '../components/showList/Newsong.vue'
import Newmv from '../components/showList/Newmv.vue'
import Intro from '../components/showList/Intro.vue'
import Singer from '../components/showList/Singer.vue'
import Singerinfo from '../components/showList/Singerinfo.vue'
import Search from '../components/showList/Search.vue'
import Personalized from '../components/showList/Personalized.vue'
import Login from '../views/Login.vue'


Vue.use(VueRouter)

// const originalPush = VueRouter.prototype.push
// VueRouter.prototype.push = function push (location) {
//   return originalPush.call(this, location).catch(err => err)
// }





const router = new VueRouter({
  routes: [
    { path: "/", redirect: '/doc' },
    // { path: '/login', component: Login },
    {
      path: '/doc',name:'doc', component: Doc, redirect:'/doc/intro', children: [
        { path: 'intro', component: Intro },
        { path: 'newsong',name:'setup', component: Newsong },
        { path: 'newmv',name:'newmv', component: Newmv },
        
        { path: 'search',name:'search', component: Search },
        { path: 'recommend', name: 'recommend', component: Personalized, },
        { path: 'singerinfo',name:'singerinfo', component: Singerinfo },
        { path: 'singer', component: Singer }
      ]
  }
  ]
})

export default router