<template>
  <!-- <HappyScroll hide-horizontal size="8" resize color="rgba(51,51,51,0.2)"> -->
  <div class="main-box">
    <!-- <Aside></Aside> -->

    <main>
      <router-view></router-view>
    </main>
  </div>
  <!-- </HappyScroll> -->
</template>

<script>
import { HappyScroll } from 'vue-happy-scroll'
// 引入css，推荐将css放入main入口中引入一次即可。

import Aside from './Aside.vue'
export default {
  components: {
    Aside,
    HappyScroll,
  },
}
</script>

<style lang="less" scoped></style>
