<template>
  <aside>
    <!-- <ul>
      <li v-for="item in documentList" :key="item.id" @click="getActiveId(item.id)">
        <router-link :class="item.className" :to="item.path"
          ><span>{{ item.title }}</span></router-link
        >
      </li>
    </ul> -->
    <el-menu @select="getIndex" :router="true" :default-active="activeName" class="el-menu-vertical-demo" background-color="#fff" active-text-color="#000">
      <el-menu-item v-for="item in menuList" :key="item.id" :index="item.index">
        <span>{{ item.title }}</span>
      </el-menu-item>
    </el-menu>
  </aside>
</template>

<script>
export default {
  created() {
    window.localStorage.setItem('activeName', '/doc/intro')
    this.activeName = window.localStorage.getItem('activeName')
  },
  watch: {
    $route(to) {
      // // // console.log(to.path)
      window.localStorage.setItem('activeName', to.path)
      this.activeName = to.path
    },
  },
  data() {
    return {
      activeName: '',
      menuList: [
        { id: 1, index: '/doc/intro', title: '个性推荐' },
        { id: 2, index: '/doc/newsong', title: '最新音乐' },
        { id: 3, index: '/doc/singer', title: '歌手推荐' },
        { id: 4, index: '/doc/newmv', title: '视频' },
      ],
    }
  },
  methods: {
    getIndex(val) {
      window.localStorage.setItem('activeName', val)
      this.activeName = val
    },
  },
}
</script>

<style lang="less" scoped>
@keyframes ad_width {
  from {
    height: 0;
  }
  to {
    height: 45px;
  }
}
aside {
  // color:#000;
  // background-color: skyblue;
  display: flex;
  // min-width: 210px;
  // height: 10vh;
  overflow: auto;
  // box-shadow: 2px 2px 6px #b3a8b2bb;
  border-right: 1px solid #ccc;
  // z-index: 999;
  // padding: 5px 10vw;
  h2 {
    padding: 0 40px;
    margin-top: 16px;
    font-weight: 400;
    margin-bottom: 10px;
  }
  .el-menu {
    border-right: 0;
    display: flex;
  }
  .el-menu-item {
    height: 40px;
    line-height: 40px !important;
  }
  .el-menu-item:hover {
    background-color: #f5f4f463 !important;
  }
  .is-active {
    // background-color: #f7eeeea8 !important;
    font-weight: 600;
    color: #6f2e6a;
  }
  // ul li {
  //   position: relative;
  //   margin: 3px 0;
  // }
  // ul li a {
  //   font-size: 14px;
  //   display: block;
  //   line-height: 40px;
  //   color: #6f2e6a; // border: 1px solid #ccc;
  // }
  // ul li a:after {
  //   content: '';
  //   position: absolute;
  //   z-index: 2;
  //   right: 0px;

  //   bottom: 0;

  //   display: block;

  //   height: 45px;

  //   width: 2px;
  //   transform: translate(-50%);
  // }
  // ul li a:hover:after {
  //   width: 1px;
  //   animation: ad_width 0.5s linear forwards;
  //   background: #ff8a00;
  // }
  // ul li:hover {
  //   background-color: #f7eeeea8;
  //   // background-color: rgb(255, 255, 255);
  // }
  // ul li a span {
  //   margin-left: 35px;
  // }
}
</style>
