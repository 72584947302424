<template>
  <div class="top-nav">
    <!-- 头部logo -->
    <div class="logo">
      <!-- <h1>
        <router-link to="/">a</router-link>
      </h1> -->
      <!-- 前进后退按钮 -->
      <ul>
        <li>
          <a @click="$router.back(-1)"><i class="el-icon-arrow-left"></i></a>
        </li>
        <li>
          <a @click="$router.forward(1)"><i class="el-icon-arrow-right"></i></a>
        </li>
      </ul>
    </div>
    <!-- <Aside></Aside> -->

    <!-- 头部导航区 -->

    <nav>
      <div class="">
        <router-link to="/doc">主页</router-link>
        <router-link to="/doc/newsong">最新单曲</router-link>
        <router-link to="/doc/singer">最热歌手</router-link>
        <router-link to="/doc/newmv">最新MV</router-link>
      </div>
    </nav>
    <!-- 右边头部信息区域 -->
    <div class="intro">
      <!-- 登录和链接 -->
      <div class="login">
        <!-- 搜索歌曲输入框 -->
        <el-input placeholder="搜索歌曲" size="small" prefix-icon="el-icon-search" @keyup.enter.native="searchSingByName" v-model="keyWords"> </el-input>

        <!-- <router-link v-if="loginFlag" to="/login"><img style="width: 30px; height: 100%" src="./assets/default_avatar.jpg" alt="" /> </router-link> -->
      </div>
      <!-- 用户头像和昵称 -->
      <!-- <div class="user" v-if="userInfo">
        鼠标放到头像上展示信息
        <el-popover placement="top-start" trigger="hover">
          <div class="show-box">
            <div class="gender">
              <h3>{{ userInfo.gender }}</h3>
              <p>动态</p>
            </div>
            <div class="new-follows">
              <h3>{{ userInfo.newFollows }}</h3>
              <p>关注</p>
            </div>
            <div class="follow-eds">
              <h3>{{ userInfo.followeds }}</h3>
              <p>粉丝</p>
            </div>
          </div>
          <div class="level">
            <ul>
              <li>
                <i class="el-icon-s-flag">介绍：</i> <span>{{ userInfo.signature }}</span>
              </li>
            </ul>
          </div>
          <template slot="reference">
            <div>
              <img v-lazy="userInfo.avatarUrl" alt="" />
            </div>
          </template>
        </el-popover>
      </div> -->
    </div>
  </div>
</template>

<script>
// import axios from 'axios'
import Aside from './Aside.vue'
export default {
  components: {
    Aside,
  },
  computed: {
    loginFlag() {
      if (sessionStorage.getItem('UserId')) {
        return false
      } else {
        return true
      }
    },
  },
  mounted() {
    // 用户登录后再localStorage里根据id查询用户的信息
    this.getUserList()
  },
  data() {
    return {
      username: '春夏秋冬里的你',
      keyWords: '',
      // 登录成功后 用户的信息
      userInfo: {},
    }
  },
  methods: {
    // 根据关键词搜索歌曲或者歌手
    searchSingByName() {
      this.$router.push({ name: 'search', query: { key: this.keyWords } })
    },
    // 根据id查询用户信息
    async getUserList() {
      const { data: res } = await this.$http.post(`/user/detail?uid=${sessionStorage.getItem('UserId')}`, { cookie: encodeURIComponent(window.sessionStorage.getItem('cookie')) })

      // console.log(res)
      this.userInfo = res.profile
    },
    // 退出事件
    // quit() {
    //   window.sessionStorage.clear()
    //   window.sessionStorage.removeItem('UserId')
    //   window.localStorage.removeItem('activeName')
    //   this.$router.push('/login')
    // },
  },
}
</script>

<style lang="less" scoped>
.top-nav {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  padding: 0px 10vw;
  background-color: rgba(253, 252, 252, 0.973);
  height: 10vh;
  display: flex;
  align-items: center;
  justify-content: space-between;

  // align-items: center;
  // // background-color: rgb(255, 133, 166);
  // background-color: rgba(248, 246, 246, 0);
  // border-bottom: 1px solid rgb(255, 0, 0);

  z-index: 99;
  // box-shadow: -1px 0px 6px #b3a8b2bb;
  .logo {
    width: 290px;
    // flex-grow: 1;
    display: flex;
    align-items: center;
    ul {
      display: flex;
      // margin-left: 5px;
      // min-width: 200px;
      li:hover {
        cursor: pointer;
        background-color: rgba(236, 236, 236, 0.86);
      }
      li {
        display: flex;
        align-items: center;
        justify-content: center;
        i {
          color: rgb(0, 0, 0);
          font-weight: 800;
          font-size: 25px;
        }
        margin-right: 5px;
        height: 40px;
        width: 40px;
        border-radius: 10%;
        text-align: center;
        // margin: 0 5px;
        // line-height: 15px;
        // background-color: rgb(216, 206, 206);
      }
    }

    h1 {
      width: 165px;
      margin-right: 80px;
      // height: 40px;
      // background-color: red;
      background: url(./assets/topbar.png) no-repeat 0 9999px;
      background-position: 0 -15px;
      a {
        display: block;
        height: 100%;
        width: 100%;
        text-indent: -9999px;
        img {
          vertical-align: middle;
        }
      }
    }
    // a {
    //   color: #5960e2;
    //   display: flex;
    //   align-items: center;
    //   justify-content: space-between;
    //   img {
    //     width: 40px;
    //     border-radius: 50%;
    //     // vertical-align: middle;
    //     margin-right: 10px;
    //   }
    // }
  }
  nav {
    // flex-grow: 1;
    min-width: 390px;
    margin: 0 auto;
    div {
      display: flex;

      a:hover {
        background-color: rgba(231, 231, 231, 0.568);
      }
      a:active {
        transform: scale(0.92);
        transition: all 0.3 !important;
      }
      a {
        color: black;
        transform: scale(1);
        // margin-right: 55px;
        padding: 10px 15px;
        font-size: 17px;
        font-weight: 600;
        transition: all 0.3;
        border-radius: 10px;
      }
      a:hover {
        text-decoration: none;
      }
    }
  }
  .login {
    display: flex;
    align-items: center;
    justify-content: center;
    .el-input {
      width: 250px;
    }
    /deep/.el-input__inner:hover {
      border-color: rgb(0, 0, 0) !important;
    }
    /deep/ .el-input__inner:focus {
      border-color: rgb(0, 0, 0) !important;
      // background-color: rgba(170, 240, 240, 0.534) !important;
    }
    a {
      line-height: 1;
      margin-left: 10px;
    }
  }
  .intro {
    display: flex;
    align-items: center;
    // margin-right: 70px;
    .user:hover {
      cursor: pointer;
    }
    .user:hover span {
      color: rgb(0, 0, 0);
      // cursor: pointer;
    }
    .user {
      display: flex;
      align-items: center;
      margin-left: 10px;

      img {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        vertical-align: middle;
      }

      span {
        color: rgb(175, 173, 173);
        font-size: 13px;
        margin-left: 5px;
        // width: 100px;

        /*超出部分就隐藏*/

        overflow: hidden;

        /*不换行设定*/

        white-space: nowrap;

        /*超出部分的文字显示省略号*/

        text-overflow: ellipsis;
      }
    }
  }
}
</style>
